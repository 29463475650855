import React, { useState, useEffect } from "react";
import defaultUserImage from "../../assets/DefaultImage/user1.jpeg"
import defaultOtherImage from "../../assets/DefaultImage/defaultImage.jpg"
import defaultTrainingImage from "../../assets/DefaultImage/schools.jpeg"

const ImageWithFallback = ({ src, alt = "", className, defaultType = "other" }) => {
  // Logique pour sélectionner l'image par défaut selon le type
  const getDefaultImage = () => {
    switch (defaultType) {
      case "user":
        return defaultUserImage;
      case "training":
          return defaultTrainingImage;
      default:
        return defaultOtherImage;
    }
  }

  const [imgSrc, setImgSrc] = useState(src || getDefaultImage());

  const handleError = () => {
    setImgSrc(getDefaultImage());
  };

  useEffect(() => {
    // Si src change, mettre à jour l'image
    setImgSrc(src || getDefaultImage());
  }, [src, defaultType]);

  return (
    <img
      src={imgSrc}
      alt={alt || "default image"}
      onError={handleError}
      className={className}
    />
  );
};

export default ImageWithFallback