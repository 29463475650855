import React, { useEffect, useState } from 'react'
import Input from '../../entrepreneur/components/form/Input'
import { Button } from '@mui/material'
import Load from '../../entrepreneur/components/load'
import Notification from '../../entrepreneur/components/feedback/Notification'
import axios from 'axios'
import { api } from '../../api'
import { Code } from '../../script/generator'

export default function VerificationEmail({ data, setData, setPage }) {
    const [options, setOptions] = useState({
        loading: false,
        disabled: true
    })
    const [isOpen, setIsOpen] = useState({
        fails: false,
        err_not_found: false
    })
    // const [code,setCode]=useState(Code())

    function handleSubmit(e) {
        const code = Code()
        e.preventDefault()
        setOptions({ ...options, loading: true })
        // setPage(prev => prev += 1)

        setData({ ...data, codeGenerated: code })
        console.log(code)
        // console.log()

        // setData({ ...data, codeGenerated: Code() })
        const form = new FormData()
        form.append('email', data.email)
        form.append('code', code)
        axios.post(api + '/api/verificationEmail', form)
            .then(response => {
                console.log(response.data)
                setOptions({ ...options, loading: false })
                setPage(prev => prev += 1)


            })
            .catch(error => {
                console.error('Error fetching data: ', error);
                if (error.code === "ERR_BAD_REQUEST") {
                    setIsOpen({ ...isOpen, err_not_found: true })
                } else {
                    setIsOpen({ ...isOpen, fails: true })
                }
                setOptions({ ...options, loading: false })
            });

}

useEffect(() => {
    if (data.email !== '') {
        setOptions({ ...options, disabled: false })
    } else {
        setOptions({ ...options, disabled: true })
    }
}, [data])
return (
    <form onSubmit={(e) => { handleSubmit(e) }} className='w-full flex flex-col gap-y-3'>
        <Input title={"Votre e-mail"} required={true} type="email" value={data.email} onChange={(e) => { setData({ ...data, email: e.target.value }) }} />
        <Button fullWidth type="submit" sx={{
            backgroundColor: '#1e293b'
        }} variant='contained' disabled={options.disabled}>
            <Load title={'Suivant'} loading={options.loading} />
        </Button>
        <Notification isOpen={isOpen.fails} type='error' message="Une erreur s'est produit." onClose={() => { setIsOpen({ ...isOpen, fails: false }) }} />
        <Notification isOpen={isOpen.err_not_found} type='error' message="E-mail introuvable." onClose={() => { setIsOpen({ ...isOpen, err_not_found: false }) }} />
    </form>
)
}
