import React, { useEffect, useState } from "react";
import Input from "../../entrepreneur/components/form/Input";
import Select from "../../entrepreneur/components/form/select";
// import Button from "../../entrepreneur/components/btn/Button";
import { useOutletContext } from "react-router-dom";
import { Button } from "@mui/material";
import axios from "axios";
import { api } from "../../api";
import { Code } from '../../script/generator'
import Load from "../../entrepreneur/components/load";
import Notification from "../../entrepreneur/components/feedback/Notification";
// import { LoadingButton } from '@mui/lab';
import ToLogin from '../../entrepreneur/components/toLogin'
export default function Account({ }) {
    const { setStep, data, setData } = useOutletContext();
    const [errorMessage, setErrorMessage] = useState("")
    const [errorEmailMessage,setErrorEmailMessage] = useState("")
    const [options, setOptions] = useState(
        {
            disabled: true,
            loading: false
        }
    )
    const [isOpen, setIsOpen] = useState({
        success: false,
        fails: false,
        fails2: false
    })
    async function createAccount() {

        setOptions({ ...options, loading: true })
        // setStep(prev => prev += 1)
        
            const form = new FormData()
            // form.append('nom', data.nom)
            form.append('email', data.email)
            // form.append('password', data.password)
            // form.append('photo', data.photo)
            form.append('code', data.code)
            // form.append('role', data.type)
            await axios.post(api + '/api/sendEmail', form)

                .then(res => {
                    console.log(res.data)
                    // localStorage.setItem('naturagerme-id', res.data.id)
                    setStep(prev => prev += 1)
                    setOptions({ ...options, loading: false })
                })
                .catch(err => {
                    console.error(err)
                    setOptions({ ...options, loading: false })
                    // setIsOpen({ ...isOpen, fails: true })
                    if(err.code==="ERR_BAD_REQUEST"){
                        setIsOpen({ ...isOpen, fails2: true })
                    } else{
                        setIsOpen({ ...isOpen, fails: true })
                    }
                })
        
    }
    const validatePassword = (password) => {
        const hasLetter = /[a-zA-Z]/.test(password);
        const hasNumber = /\d/.test(password);
        return hasLetter && hasNumber;
    };
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    
    useEffect(() => {
        setData({ ...data, code: Code() })
    }, [])
    useEffect(() => {
        if (

            data.email !== '' && // Add this condition
            data.password !== '' &&
            data.nom !== '' &&
            data.photo !== null &&
            data.confirmation_password !== '' &&
            data.password === data.confirmation_password &&
            data.password.length >= 6&&
            validatePassword(data.password)&&
            validateEmail(data.email)

        ) {
            setOptions({ ...options, disabled: false })
        } else {
            setOptions({ ...options, disabled: true })
        }
    }, [data])
    useEffect(() => {
        if (data.password.length < 6) {
            
            setErrorMessage("Minimum 6 caractères")
            setOptions({ ...options, disabled: true })
        }
        else {
            if(validatePassword(data.password)){
                setErrorMessage("")
                setOptions({ ...options, disabled: false })

            } else {
                setErrorMessage("doit contenir des lettres et des chiffres")
                setOptions({ ...options, disabled: true })
            }
        }
       
    }, [data.password])
    useEffect(()=>{
       
            if(validateEmail(data.email)){
                setErrorEmailMessage("")
                setOptions({ ...options, disabled: false })

            } else {
                setErrorEmailMessage("E-mail invalide")
                setOptions({ ...options, disabled: true })
            }
        
       
    },[data.email])
    return (
        <>
            <form onSubmit={(e) => { e.preventDefault(); createAccount(); }} className="w-11/12 flex flex-col gap-y-2">
                <span className="font-black text-3xl">
                    Créer votre compte
                </span>

                <div className="w-full flex flex-row justify-between items-center gap-x-3">
                    <Input required={true} title={"Nom"} placeholder={"Votre nom"} value={data.nom} onChange={(e) => { setData({ ...data, nom: e.target.value }) }} />
                    <Input title={"Prénom"} placeholder={"Votre prénom"} value={data.prenom} onChange={(e) => { setData({ ...data, prenom: e.target.value }) }} />
                </div>
                <div className="w-full flex flex-row justify-between items-center gap-x-3">
                    {/* <Input title={"Nom"} placeholder={"Votre nom"} value={data.nom} onChange={(e) => { setData({ ...data, nom: e.target.value }) }} /> */}
                    <Input required={true} error_message={errorEmailMessage} title={"E-mail"} type="email" placeholder={"Votre adresse e-mail"} value={data.email} onChange={(e) => { setData({ ...data, email: e.target.value }) }} />
                </div>
                <div className="w-full flex flex-row justify-between items-end gap-x-3">
                    <Input required={true} title={"Mot de passe "} error_message={errorMessage} type="password" value={data.password} onChange={(e) => { setData({ ...data, password: e.target.value }) }} />
                    <Input required={true} wrong={data.password !== data.confirmation_password && data.password !== ''} title={"Confirmation de mot de passe"} type="password" value={data.confirmation_password} onChange={(e) => { setData({ ...data, confirmation_password: e.target.value }) }} />
                </div>
                <Input required={true} title={"Photo de profil"} type="file" onChange={(e) => { setData({ ...data, photo: e.target.files[0] }) }} />
                <Select title={"Type de compte"} value={data.type} onChange={(e) => { setData({ ...data, type: e.target.value }) }} values={["Entrepreneur", "Formateur"]} />
                <div className="w-full flex max-lg:flex-col-reverse gap-y-2 justify-between item-center">
                    <ToLogin />
                    <Button size="small" type="submit" disabled={options.disabled} sx={{
                        bgcolor: "#1e293b",
                        color: "white",
                        "&:hover": {
                            backgroundColor: "#1e293b", // Change the hover background color
                            color: "white", // Change the hover text color
                        },
                    }} variant='contained' className="px-9 ">
                        <Load title="S'inscrire" loading={options.loading} />
                    </Button>
                </div>
            </form>
            <Notification type="error" message="Une erreur s'est produite." isOpen={isOpen.fails} onClose={() => { setIsOpen({ ...isOpen, fails: false }) }} />
            <Notification type="error" message="E-mail existe déjà." isOpen={isOpen.fails2} onClose={() => { setIsOpen({ ...isOpen, fails2: false }) }} />
        </>
    )
}