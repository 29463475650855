import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import user1 from '../../../assets/images/icons8-test-account-96.png' // Placeholder image
import file from '../../../assets/icons/file-o.svg'
import axios from 'axios'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { api } from '../../../api'
import NoResults from '../../components/NoResults'
import Status from '../../components/Status'
import Modal from '../../components/Modals'
import ImageWithFallback from '../../components/ImageWithFallback'

const Inscription = () => {
  const navigate = useNavigate()
  const [inscriptions, setInscriptions] = useState([])
  const [isLoading, setIsLoading] = useState(true) // État pour le chargement
  // const user_id = 10
  const user_id = localStorage.getItem('naturagerme-id');
  const {setTitle} = useOutletContext()
  // const [isOpen, setisOpen] = useState({
  //   show: false,
  //   variant: 'default',
  //   title: '',
  //   message: ''
  // })

  useEffect(() => {
    setTitle('Inscription')
    axios.get(api + `/api/listeInscriptionsFormateur/${user_id}`)
      .then(response => {
        setInscriptions(response.data)
      })
      .catch(error => {
        console.error("Il y a eu une erreur lors de la récupération des inscriptions!", error)
      })
      .finally(() => {
        setIsLoading(false) // Fin du chargement
      })
  }, [setTitle])

  return (
    <>
      {/* <Header title="Inscription" /> */}

      <div className='border-line mx-4 lg:mx-0 p-6 mt-6 min-h-[570px]'>
        <div className='flex w-full justify-between items-center mb-6'>
          <div className="flex flex-col gap-2 text-start">
            <h5 className='text-xl font-semibold'>Liste des inscriptions</h5>
            <p>Retrouvez tous les inscriptions de vos séminaires</p>
          </div>
        </div>
        <div>
          {isLoading ? (
            <div className="flex justify-center items-center mt-60">
              {/* <div className="w-16 h-16 border-4 border-t-4 border-t-transparent border-gray-600 rounded-full animate-spin"></div> */}
              <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-900"></div>
            </div>
          ) : (
            inscriptions.length > 0 ? (
              <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                    <div className="overflow-hidden border rounded-md">
                      <table className="min-w-full text-left text-sm font-light text-surface dark:text-white ">
                        <thead className="border-b border-neutral-200 font-medium dark:border-white/10">
                          <tr>
                            <th scope="col" className="px-6 py-4">Candidats</th>
                            <th scope="col" className="px-6 py-4">Titre de la formation</th>
                            <th scope="col" className="px-6 py-4">Etat</th>
                            <th scope="col" className="px-6 py-4">Date de création</th>
                            <th scope="col" className="px-6 py-4">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {inscriptions.map((inscription) => (
                            <tr key={inscription.id} className="border-b border-neutral-200 transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-white/10 dark:hover:bg-neutral-600">
                              <td className="flex px-6 py-4 font-medium gap-4">
                               
                              <ImageWithFallback src={api + '/storage/' + inscription.user?.photo} alt={inscription.user?.nom} className='w-12 h-12 rounded-full' defaultType='user'/>
                                {/* <img src={user1} alt={inscription.user.nom} className='w-12 h-12 rounded-full' /> */}
                                <div className="flex flex-col justify-between gap-2">
                                  <p>{inscription.user?.nom} {inscription.user?.donnee_perso1?.prenom}</p>
                                  <p>{inscription.user?.email}</p>
                                </div>
                              </td>
                              <td className='px-6 py-4'>{inscription.formation.titre}</td>
                              <td>
                              <Status text={inscription.etat}/>
                              </td>
                              <td>{new Date(inscription.created_at).toLocaleDateString()}</td>
                              <td>
                                <button className='btn-black-outline' onClick={() => navigate(`/formateur/inscription/${inscription.id}`)}>
                                  <img src={file} alt="Consulter" className='w-5 h-5' />
                                  Consulter
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <NoResults showMessage={true} />
            )
          )}
        </div>
      </div>

    </>
  )
}

export default Inscription
