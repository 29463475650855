import React, { useEffect, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { faLock} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { api } from "../../api"
import PasswordModal from "./PasswordModal"
import Modals from '../../entrepreneur/components/modal'
import { Button } from '@mui/material'
import userIcon from '../../assets/images/icons8-test-account-96.png'


export default function Dropdown() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const [showPasswordModal, setShowPasswordModal] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [confirm, setConfirm] = useState(false)

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const trigger = useRef(null)
  const dropdown = useRef(null)
  const navigate = useNavigate()
  const [profil, setProfil] = useState([])
  const user_id = 10
  // close on click outside
  useEffect(() => {

    // axios.get(api + `/api/getProfilFormateur/${user_id}`)
    // .then(response =>{
    //   setProfil(response.data);
    // })
    // .catch(error => {

    // })


    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    }
    document.addEventListener("click", clickHandler)
    return () => document.removeEventListener("click", clickHandler)
  });

  const logout = () =>{
    setShowLogoutModal(true)
    setConfirm(false)
    // if(localStorage.getItem('user_id') !== null)localStorage.removeItem('user_id')
      localStorage.removeItem("naturagerme-id")
      localStorage.removeItem("naturagerme-email")
      localStorage.removeItem("naturagerme-type")
      localStorage.removeItem("naturagerme-photo")
      localStorage.removeItem("naturagerme-name")
      navigate('/')
  }


  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <section className="bg-gray-2  dark:bg-dark">
      <div className="container">
        <div className="flex justify-center">
          <div className="relative inline-block">
            <button
              ref={trigger}
              onClick={() => setDropdownOpen(!dropdownOpen)}
              className="border 
               inline-flex h-12 items-center justify-center gap-2 rounded-full   pl-2 pr-6 py-3 text-sm font-medium text-dark  dark:bg-dark-2 dark:text-white"
            >
                <img 
                src={api + '/storage/' + localStorage.getItem('naturagerme-photo')} 
                className=" bg-slate-200 rounded-full h-10 w-10"
                onError={(e) => { e.target.onerror = null; e.target.src = userIcon; }}
                />
              {localStorage.getItem('naturagerme-email') !== null ? localStorage.getItem('naturagerme-email') :<span>Vous</span>}

               {/* <img src={user1} className=" bg-slate-300 rounded-full h-10 w-10"/> */}
               {/* jules.arimanana@gmail.com */}

              <span
                className={`w-5 h-5 duration-100 flex items-center ${dropdownOpen ? "-scale-y-100 " : ""}`}
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 14.25C9.8125 14.25 9.65625 14.1875 9.5 14.0625L2.3125 7C2.03125 6.71875 2.03125 6.28125 2.3125 6C2.59375 5.71875 3.03125 5.71875 3.3125 6L10 12.5312L16.6875 5.9375C16.9688 5.65625 17.4062 5.65625 17.6875 5.9375C17.9688 6.21875 17.9688 6.65625 17.6875 6.9375L10.5 14C10.3437 14.1562 10.1875 14.25 10 14.25Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </button>
            <div
              ref={dropdown}
              onFocus={() => setDropdownOpen(true)}
              onBlur={() => setDropdownOpen(false)}
              className={`absolute right-0 top-full w-[240px] divide-y divide-stroke overflow-hidden rounded-lg bg-white  shadow-md dark:divide-dark-3 dark:bg-dark-2 ${dropdownOpen ? "block" : "hidden"}`}
            >
             
              <div>
                <Link
                  to="/formateur/parametre"
                  className="flex w-full items-center justify-between px-4 py-2.5 text-sm font-medium text-dark hover:bg-gray-50 dark:text-white dark:hover:bg-white/5"
                >
                  <span className="flex items-center gap-2">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 7C6.125 7 4.625 5.525 4.625 3.725C4.625 1.925 6.125 0.449997 8 0.449997C9.875 0.449997 11.375 1.925 11.375 3.725C11.375 5.525 9.875 7 8 7ZM8 1.575C6.75 1.575 5.75 2.55 5.75 3.725C5.75 4.9 6.75 5.875 8 5.875C9.25 5.875 10.25 4.9 10.25 3.725C10.25 2.55 9.25 1.575 8 1.575Z"
                        fill="currentColor"
                      />
                      <path
                        d="M13.275 15.575C12.975 15.575 12.7 15.325 12.7 15V14.275C12.7 11.675 10.6 9.575 8.00003 9.575C5.40002 9.575 3.30002 11.675 3.30002 14.275V15C3.30002 15.3 3.05002 15.575 2.72502 15.575C2.40002 15.575 2.15002 15.325 2.15002 15V14.275C2.15002 11.05 4.77502 8.45 7.97502 8.45C11.175 8.45 13.8 11.075 13.8 14.275V15C13.825 15.3 13.575 15.575 13.275 15.575Z"
                        fill="currentColor"
                      />
                    </svg>
                    Mon profil
                  </span>
                </Link>
               
              </div>
              <div>
                <button
                onClick={()=>openModal()}
                  to="/formateur/parametre"
                  className="flex w-full items-center justify-between px-5 py-2.5 text-sm font-medium text-dark hover:bg-gray-50 dark:text-white dark:hover:bg-white/5"
                >
                  <span className="flex items-center gap-2">
                  <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                    Mot de passe
                  </span>
                </button>
               
              </div>

              <div>
                <button
                onClick={()=>setConfirm(true)}
                className="flex w-full items-center justify-between px-4 py-2.5 text-sm font-medium text-dark hover:bg-gray-50 dark:text-white dark:hover:bg-white/5">
                  <span className="flex items-center gap-2">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.3 0.449997H8.47502C7.82502 0.449997 7.27502 0.999997 7.27502 1.65V3.375C7.27502 3.675 7.52502 3.925 7.82502 3.925C8.12502 3.925 8.40002 3.675 8.40002 3.375V1.625C8.40002 1.575 8.42502 1.55 8.47502 1.55H11.3C11.9 1.55 12.375 2.025 12.375 2.625V13.35C12.375 13.95 11.9 14.425 11.3 14.425H8.47502C8.42502 14.425 8.40002 14.4 8.40002 14.35V12.625C8.40002 12.325 8.15002 12.075 7.82502 12.075C7.50002 12.075 7.27502 12.325 7.27502 12.625V14.35C7.27502 15 7.82502 15.55 8.47502 15.55H11.3C12.525 15.55 13.5 14.55 13.5 13.35V2.65C13.5 1.425 12.5 0.449997 11.3 0.449997Z"
                        fill="currentColor"
                      />
                      <path
                        d="M4.39998 8.55H8.87498C9.17498 8.55 9.42498 8.3 9.42498 8C9.42498 7.7 9.17498 7.45 8.87498 7.45H4.42498L5.97498 5.875C6.19998 5.65 6.19998 5.3 5.97498 5.075C5.74998 4.85 5.39998 4.85 5.17498 5.075L2.67498 7.625C2.44998 7.85 2.44998 8.2 2.67498 8.425L5.17498 10.975C5.27498 11.075 5.42498 11.15 5.57498 11.15C5.72498 11.15 5.84998 11.1 5.97498 11C6.19998 10.775 6.19998 10.425 5.97498 10.2L4.39998 8.55Z"
                        fill="currentColor"
                      />
                    </svg>
                    Se déconnecter
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
        <PasswordModal isOpen={isModalOpen} onClose={closeModal} />
        <Modals isOpen={confirm} onClose={() => { setConfirm(false) }}>
                <h4>Confirmation de deconnexion</h4>
                <p className='my-2'>
                    Êtes-vous sûr de vouloir vous déconnecter ?
                </p>
                <div className='w-full flex flex-row  gap-x-4 justify-between items-center mt-4'>
                    <Button variant="outlined" sx={{
                        color: 'black',
                        borderColor: 'black',
                        width: "100%",
                        "&:hover": {
                            backgroundColor: "slategray", // Change the hover background color
                            color: "white", // Change the hover text color
                        },
                    }} onClick={() => { setConfirm(false) }}>
                        Annuler
                    </Button>
                    <Button
                        onClick={() => {logout() }}
                        sx={{
                            bgcolor: "#FF7D00",
                            color: "white",
                            width: "100%",
                            "&:hover": {
                                backgroundColor: "#FF9933", // Change the hover background color
                                color: "white", // Change the hover text color
                            },
                        }} variant='contained' className="px-9 ">
                        Confirmer
                    </Button>
                </div>
            </Modals>
    </section>
  );
}
