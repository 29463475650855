import React from 'react'
import noDataFound from '../../assets/images/9315315.jpg'

function NoResults({ showMessage }) {
  if (!showMessage) return null;

  return (
    <div className="flex flex-col items-center justify-center h-full mt-32">
      <img 
        src={noDataFound} 
        alt="Aucun résultat trouvé" 
        className="w-96 h-96 object-contain mb-4" 
      />
      <p className="text-lg text-gray-600">Aucun résultat trouvé</p>
    </div>
  )
}

export default NoResults;
