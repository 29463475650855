import React from 'react';

export default function Details() {
    return (
        <div className="w-11/12 flex flex-wrap justify-center items-stretch gap-8 py-6 my-8 z-10">
  {/* Inscription simplifiée */}
  <div className="w-[400px] max-md:w-full bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 flex flex-col justify-start items-center">
    <span className="text-2xl font-bold text-center text-gray-600 leading-snug">
      Inscription simplifiée
    </span>
    <ul className="flex flex-col gap-y-4 mt-4 text-gray-600 text-base">
      <li className='text-xl'>
        <b className="text-gray-600 text-xl">▪ Pour les entrepreneurs :</b> Inscrivez-vous facilement aux formations recommandées et accédez à des programmes qui vous aideront à développer vos compétences et à atteindre vos objectifs professionnels.
      </li>
      <li className='text-xl'>
        <b className="text-gray-600 text-xl">▪ Pour les formateurs :</b> Rejoignez notre réseau d'experts, mettez à jour vos compétences et disponibilités, et proposez des formations sur mesure pour répondre aux besoins des entrepreneurs.
      </li>
    </ul>
  </div>

  {/* Génération des rapports */}
  <div className="w-[400px] max-md:w-full bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 flex flex-col justify-start items-center">
    <span className="text-2xl font-bold text-center text-yellow-500 leading-snug">
      Génération des rapports
    </span>
    <ul className="mt-4 text-gray-600 text-base">
      <li className='text-xl'>
        Après chaque analyse des besoins, NATURA GERME permet aux formateurs de créer des rapports détaillés. Ces rapports contiennent des recommandations personnalisées, des objectifs de formation, et des plans d'action, facilitant ainsi la mise en place de formations efficaces et ciblées.
      </li>
    </ul>
  </div>

  {/* Facilités */}
  <div className="w-[400px] max-md:w-full bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 flex flex-col justify-start items-center">
    <span className="text-2xl font-bold text-center text-gray-600 leading-snug">
      Facilités
    </span>
    <ul className="flex flex-col gap-y-4 mt-4 text-gray-600 text-base">
      <li className=' text-xl'>
        <b className="text-gray-600 text-xl">▪ Analyse automatisée des besoins :</b> Détectez facilement les domaines de formation essentiels pour un développement optimal.
      </li>
      <li className=' text-xl'>
        <b className="text-gray-600 text-xl">▪ Gestion des inscriptions :</b> Réservez vos places ou inscrivez des entrepreneurs aux sessions de formation.
      </li>
      <li className=' text-xl'>
        <b className="text-gray-600 text-xl">▪ Suivi des progrès :</b> Suivez l'impact des formations et mesurez les améliorations des compétences.
      </li>
    </ul>
  </div>
</div>

    );
}