import React from 'react';
// import Button from '../../../components/btn/Button';\
// Button
import img from '../../../../assets/reunion.jpg'
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
// import { faSearch } from '@fortawesome/free-solid-svg-icons';

export default function Introduction() {
    const navigate = useNavigate()
    return (
        <div className="main-card">
            <div className="main-card-text ">
                <h3 className='text-5xl font-semibold'>
                    Optimiser la formation des entrepreneurs avec efficacité
                </h3>
                <p className='text-2xl'>
                    Transformez vos compétences et faites croître votre entreprise grâce à NATURA GERME, la solution innovante pour analyser les besoins en formation et gérer les sessions de formation.
                </p>
                {
                    !localStorage.getItem("naturagerme-id") && (
                        <div className='main-card-button'>
                            <button className='login text-2xl font-semibold my-1 mx-3' onClick={() => { navigate('/Login') }}>Se connecter</button>
                            <button className='signup text-2xl font-semibold my-1' onClick={() => { navigate('/Signup') }}>S'inscrire</button>
                        </div>
                    )
                }
                {/* <faSearch></faSearch> */}
            </div>
            <img src={img} alt='none' className="main-card-image" />
            <div className='main-card-mask'></div>
        </div>
    )
}