import React, {useEffect, useState} from 'react'
import user1 from '../../../assets/users/prestataire1.png'
import Header from '../../components/Header'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import axios from 'axios'
import { api } from '../../../api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import file from '../../../assets/icons/eye-svgrepo-com.svg'
import ImageWithFallback from '../../components/ImageWithFallback'

const ConsulterFormation = () => {
  const navigate = useNavigate()
  const {id} = useParams()
  const [form1, setForm1] = useState([]) // État pour stocker les données des formations
  const [info, setInfo] = useState([]) // État pour stocker les données des formations
  const {setTitle} = useOutletContext()

  useEffect(() => {
    setTitle("Formation")
    // Requête API pour récupérer les formations
    axios.get(api + `/api/getDetailFormation/${id}`)
      .then(response => {
        setForm1(response.data);
        // console.log(response.data) // Mettre à jour l'état avec les données récupérées
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des formations:", error);
      })

      axios.get(api + `/api/getInfoEntrepFormation/${id}`)
      .then(response => {
        setInfo(response.data);
        console.log(response.data) // Mettre à jour l'état avec les données récupérées
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des formations:", error);
      })
  }, [setTitle])

  const formatDate = (date) => {
    if (!date) return ''; // Vérifie que la date est définie
    const [year, month, day] = date.split('-');
    return `${day}-${month}-${year}`;
};
  
  return (
    <>
    {/* <Header title="Formation"/> */}
    <div className='border-line mx-4 lg:mx-0 p-6 mt-6 min-h-[570px]'>
    <div className='flex w-full justify-between items-center mb-6'>
    <div className="flex items-center gap-2 ">
          <button className={"  border  rounded-full p-2 w-[34px]"} onClick={()=>navigate('/formateur/formation')} >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <h1 className='text-xl font-semibold'>Détail d'une formation</h1>
      </div>
        <button className='btn-black' onClick={()=>navigate('/formateur/rapport/' + id)}>Imprimer un rapport ABF</button>
      </div>
      <div>
        <table className="tableDetail">
            <thead>
                <th>Titre</th>
                <th>Durée</th>
                <th>Date début</th>
                <th>Date fin</th>
            </thead>
            {/* {form1.length} */}
            <tbody>
              {
                form1.length > 0 ? form1.map((item, key) => (
                  <tr key={key}>
                    <td>{item.titre}</td>
                    <td>{item.duree}</td>
                    <td>{formatDate(item.dateDebut)}</td>
                    <td>{formatDate(item.dateFin)}</td>
                  </tr>
                )) : (
                  <tr>
                    <td colSpan="4">Aucune formation trouvée</td>
                  </tr>
                )
              }
            </tbody>
            
        </table>

        {/* <div>
          <h3>Liste des formateurs : {form1.map((item, key) => (
            <p key={key}>{item.nom}</p>
          ))}</h3>
        </div> */}

        {/* liste des entreprenneur */}
        <div className="overflow-hidden w-full border rounded-md">
        <table className="min-w-full text-left text-sm font-light text-surface dark:text-white">
  <thead className="border-b border-neutral-200 font-medium dark:border-white/10">
    <tr>
      <th scope="col" className="px-6 py-4">Candidat</th>
      <th scope="col" className="px-6 py-4">Sexe</th>
      <th scope="col" className="px-6 py-4">Adresse </th>
      <th scope="col" className="px-6 py-4">Contact</th>
      <th scope="col" className="px-6 py-4">Action</th>
    </tr>
  </thead>
  <tbody>

      {
        info.map((item, key) => (
          <tr key={key} className="border-b border-neutral-200 transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-white/10 dark:hover:bg-neutral-600">
            <td className="flex px-6 py-4 font-medium gap-4">
              {/* <img src={api + '/storage/' + item.photo} alt="..." className='w-12 h-12 rounded-full'/> */}
              <ImageWithFallback src={api + '/storage/' + item.photo} alt={item.photo} className='w-12 h-12 rounded-full' defaultType='user'/>
              <div className="flex flex-col justify-between gap-2">
                <p>{item?.nom} {item?.donnee_perso1?.prenom}</p>
                <p>{item?.email}</p>
              </div>
            </td>
            <td>{item?.donnee_perso1?.sexe}</td>
            <td>{item?.donnee_perso1?.adressePostale}</td>
            <td>{item?.donnee_perso1?.tel}</td>
            <td>
            <button className='btn-black-outline' onClick={() => navigate('/formateur/entrepreneur/'+item.id)}> {/* Bouton pour naviguer vers les détails de l'entrepreneur */}
              <img src={file} alt="..." className='w-5 h-5' /> {/* Icône du bouton */}
              Détail
            </button>
            </td>
          </tr>
        ))
      }

{/*     
    <tr className="border-b border-neutral-200 transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-white/10 dark:hover:bg-neutral-600">
      <td className="flex px-6 py-4 font-medium gap-4">
        <img src={user1} alt="..." className='w-12 h-12 rounded-full'/>
        <div className="flex flex-col justify-between gap-2">
          <p>Andriana</p>
          <p>andriana@gmail.com</p>
        </div>
      </td>
      <td>12346</td>
      <td>Féminin</td>
      <td>456 Rue Exemple, Ville</td>
      <td>+123 456 789</td>
      <td>
        <button className='btn-black'>
          Consulter
        </button>
      </td>
    </tr>
    <tr className="border-b border-neutral-200 transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-white/10 dark:hover:bg-neutral-600">
      <td className="flex px-6 py-4 font-medium gap-4">
        <img src={user1} alt="..." className='w-12 h-12 rounded-full'/>
        <div className="flex flex-col justify-between gap-2">
          <p>Andriana</p>
          <p>andriana@gmail.com</p>
        </div>
      </td>
      <td>12347</td>
      <td>Féminin</td>
      <td>789 Rue Exemple, Ville</td>
      <td>+123 456 789</td>
      <td>
        <button className='btn-black'>
          Consulter
        </button>
      </td>
    </tr> */}
  </tbody>
</table>
        </div>
        


    </div>   
    </div>
   
    </>
  )
}

export default ConsulterFormation