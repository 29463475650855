import React, { useEffect, useState } from 'react'
import Back from '../entrepreneur/components/btn/back'
import Input from '../entrepreneur/components/form/Input'
import VerificationEmail from './VerificationEmail'
import VerificationCode from './VerificationCode'
import ChangePassword from './ChangePassword'
import bg from '../assets/reunion.jpg'

export default function ForgotPassword() {
    const [data, setData] = useState({
        email: '',
        code: '',
        codeGenerated: '',
        password: '',
        confirmPassword: '',
    })
    const [page, setPage] = useState(1)
    useEffect(() => {
        console.log(data)
    }, [data])
    return (
        <div className="w-screen max-w-screen  flex gap-x-6 flex-row justify-center items-center bg-white">
             {/* <div className="w-8/12 h-screen bg-slate-50 flex flex-col justify-center items-center max-lg:hidden">
                <img src={bg} className='w-full h-full object-cover'/>
            </div> */}
            <div className='max-md:w-11/12  max-lg:w-6/12 w-4/12 mx-10 p-4 mb-24'>
                {/* <form > */}
                <div className='flex flex-row justify-start items-center gap-x-4 mb-5'>
                    <Back />
                    <h4>Mot de passe oublier</h4>
                </div>
                <p>
                    {/* Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam, totam? */}
                </p>
                {
                    page == 1 && (
                        <VerificationEmail setPage={setPage} data={data} setData={setData} />
                    )
                }
                {
                    page == 2 && (
                        <VerificationCode setPage={setPage} data={data} setData={setData} />
                    )
                }
                {
                    page == 3 && (
                        <ChangePassword setPage={setPage} data={data} setData={setData} />
                    )
                }
                {/* </form> */}
            </div>
        </div>
    )
}
