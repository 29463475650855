import { useState } from 'react'
import DiplomaModal from "../../../components/DiplomaModal"

function Diplome() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [diplomas, setDiplomas] = useState([]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleAddDiploma = (diploma) => {
    setDiplomas([...diplomas, diploma]);
    closeModal();
  };

  return (
    <>
      <div className='border-line p-4 mb-4' id="diplomas">
        <h5 className='text-lg mb-4 text-start'>Diplôme & Certificat</h5>
        <button className='btn-black' onClick={openModal}>Ajouter un diplôme</button>

        {/* Liste des diplômes ajoutés */}
        <ul className="mt-4">
          {diplomas.map((diploma, index) => (
            <li key={index} className="mb-2">
              {diploma.type}: {diploma.fileName}
            </li>
          ))}
        </ul>
      </div>

      {/* Modal pour ajouter un diplôme */}
      {isModalOpen && (
        <DiplomaModal onClose={closeModal} onSave={handleAddDiploma} />
      )}
    </>
  );
}


export default Diplome