import React from 'react'
import Logo from '../../assets/icons/logo.png'
import { NavLink, Link, useLocation } from "react-router-dom"
import { NavbarLinks } from '../../datas/Datas'



const Sidebar = () => {
  const location = useLocation()

  const isActive = (path) => {
    if (path === location.pathname) {
      return true;
    }
    return false;
  }

  return (
    <>
        <div
        // className={` w-[300px] fixed bottom-0 top-0 z-40 flex h-screen  flex-col justify-between bg-white dark:bg-gray-900 dark:text-white px-8 pb-6 pt-10 text-black transition-all duration-300 md:hidden rounded-r-xl shadow-md`}
        className='
        lg:flex hidden
         fixed m-4 h-[calc(100vh-32px)] min-w-[260px]  justify-between flex-col bg-white  text-black transition-all duration-300 rounded-xl shadow-md'
      >
        <div>
        <div className=' mx-4 border-b-2 border-black-3/10 py-6 flex items-center '>
          
        <img src={Logo} alt="" className="h-20 w-20 mx-auto" />
        </div>
        <div className="mt-4">
          <nav>
            <ul className=" text-xl text-start px-4">
              {NavbarLinks.map((data) => (
                <li key={data.name}>
                  <Link
                    to={data.link} 
                    // className={'mb-5 flex gap-4 text-black-900 text-base px-4 '}
                    className={`mb-1 flex gap-4 text-base px-4 rounded-md py-3 items-center ${
                      isActive(data.link) ? "bg-primary text-black-1 " : "bg-white text-black-3"}`} 
                    >
                  
                    <img src={isActive(data.link)?data.icon2:data.icon} alt="..." className='w-6 h-6'/>
                    {data.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
        <div className="border-t-2 border-black-1/10 py-4">
          <p className='font-light text-xs mx-auto text-center'>
            @ Copyright Haisoa 
          </p>
        </div>
      </div>
    </>
  )
}

export default Sidebar