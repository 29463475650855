import React, { useEffect, useState } from 'react';

const Status = ({ text }) => {
  const [statusClass, setStatusClass] = useState('');

  useEffect(() => {
    // Définir la classe CSS en fonction du statut
    switch (text) {
      case 'Confirmé':
        setStatusClass('status-confirmed');
        break;
        case 'Acceptée':
          setStatusClass('status-confirmed');
          break;
      case 'En attente':
        setStatusClass('status-pending');
        break;
      case 'Réfusée':
      case 'Refusée':
        setStatusClass('status-refused');
        break;
      default:
        setStatusClass('status-unknown');
        break;
    }
  }, [text]);

  return (
    <div className={`status ${statusClass}`}>
      <div className='min-w-[10px] h-[10px] rounded-full inline-block mr-2'></div>
      {text}
    </div>
  );
}

export default Status;
