import React from "react";

export default function Select({ title,value, onChange, values = [], }) {
    return (
        <div className='flex flex-col justify-start items-start gap-y-1 w-full'>
            <label className='input_label'>{title}</label>
            <select name="" id="" className="input w-full" onChange={onChange}>
            
                {
                    values.map((item, index) => (
                        <option value={item}  key={index}>{item}</option>
                    ))
                }
            </select>
        </div>
    )
}