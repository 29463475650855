import React, { useState } from 'react';
import CustomStepper from '../../components/stepper/stepper';
import { Outlet } from 'react-router-dom';
import { Button } from '@mui/material';
import { IoArrowBack } from 'react-icons/io5';
// import Button from '../../components/btn/Button';

// create function user
export default function ABFLayout() {
    const [activeStep, setActiveStep] = useState(0)
   
    const [data, setData] = useState({
        entrepriseFamille1: 0,
        entrepriseFamille2: 0,
        entrepriseFamille3: 0,
        marketing1: 0,
        marketing2: 0,
        marketing3: 0,
        approvisionnement1: 0,
        approvisionnement2: 0,
        approvisionnement3: 0,
        gestionStock1: 0,
        gestionStock2: 0,
        gestionStock3: 0,
        cout1: 0,
        cout2: 0,
        cout3: 0,
        tenueRegistre1: 0,
        tenueRegistre2: 0,
        tenueRegistre3: 0,
        planificationFin1: 0,
        planificationFin2: 0,
        planificationFin3: 0,
        persoProd1: 0,
        persoProd2: 0,
        persoProd3: 0,

        Marketing: 0,
        Approvisionnement: 0,
        gestionStocks: 0,
        couts: 0,
        Comptabilité: 0,
        planuficationFinanciere: 0,
        personnel: 0,
        infoMarche: 0,
        registreCommerce: 0,
        AssistanceJuridique: 0,
        infoTechno: 0,
        formationTech: 0,
        assurance: 0,
        credit: 0,
        devises: 0,
        mp: 0,
        Associations: 0,
        autre: "",
        autre2: "",

        form1Montant1: '',
        form1Date: '',
        form1Montant2: '',
        form2Montant1: '',
        form2Date: '',
        form2Montant2: '',
        form3Montant1: '',
        form3Date: '',
        form3Montant2: '',
        form4Montant1: '',
        form4Date: '',
        form4Montant2: '',
        form5Montant1: '',
        form5Date: '',
        form5Montant2: '',
        form6Montant1: '',
        form6Date: '',
        form6Montant2: '',
        form7Montant1: '',
        form7Date: '',
        form7Montant2: '',
        form8Montant1: '',
        form8Date: '',
        form8Montant2: '',
        form8Pourcentage:'',
        sessionVolonte3:0,
        sessionDate3:'',
        sessionMontant3:0


    })

    return (
        <div className='w-screen flex flex-col justify-start items-center py-7 bg-slate-50'>
            <span className='text-4xl font-semibold'>Analyse des besoin en formation</span>
            <div className='flex w-11/12 flex-row  justify-between items-start mt-8'>
                <div className='w-3/12'>
                    <CustomStepper activeStep={activeStep} />
                </div>
                <div className='w-8/12 bg-white card p-3 '>

                    {
                        activeStep > 0 && (
                            <Button onClick={() => { setActiveStep(prev => prev -= 1) }}>
                                <div className='flex flex-row justify-start items-center gap-x-3'>
                                    <IoArrowBack />
                                    <span>Retour</span>
                                </div>
                            </Button>
                        )
                    }
                    <Outlet context={{ activeStep, setActiveStep, data, setData }} />

                </div>
            </div>
        </div>
    )
}