import React from 'react';
import Select from '../../Select'
import { selectInducateur } from '../../../../data';
import { useOutletContext } from 'react-router-dom';
import { Button } from '@mui/material';

export default function Indicateur5({ setPage }) {
    const { data, setData } = useOutletContext()
    function handleSubmit(e) {
        e.preventDefault()
        setPage(prev => prev += 1)
    }
    return (
        <>
            <form onSubmit={handleSubmit} className='flex flex-col justify-start mb-2 items-center gap-y-2 w-full'>
                <span className="title text-left w-full">Coûts</span>
                <Select title={"Calcule les coûts directs des produits / services"} values={selectInducateur} onChange={(e) => { setData({ ...data, cout1: Number(e.target.value) }) }} />
                <Select title={"Calcule les coûts directs de la main-d’œuvre"} values={selectInducateur} onChange={(e) => { setData({ ...data, cout2: Number(e.target.value) }) }} />
                <Select title={"Calcule les coûts indirects"} values={selectInducateur} onChange={(e) => { setData({ ...data, cout3: Number(e.target.value) }) }} />
                <div className='w-full flex flex-row justify-end items-center'>
                    <Button variant='contained' type='submit' style={{ backgroundColor: 'slategray' }}>
                        Suivant
                    </Button>
                </div>
            </form>
        </>
    )

}