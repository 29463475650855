// import { Stepper } from "@mui/material";

import React from 'react';
import { Stepper, Step, StepLabel, StepContent } from "@mui/material";
// import { Box } from "@mui/system";
// import { styled } from "@mui/material/styles";
// import { TextField } from "@mui/material";
// import { Typography } from "@mui/material";
// import { Button } from "@mui/material";
// create fuction 

const CustomStepper = ({ activeStep }) => {

  return (

    <Stepper activeStep={activeStep} orientation='vertical'>
      {/* Add your steps here */}
      <Step >
        <StepLabel >Indicateurs des compétences en gestion et analyse des besoins en formation</StepLabel>

      </Step>
      <Step >
        <StepLabel >
          Manuels Germe en possession de l’entrepreneur  et
          autres besoins que la formation Germe
        </StepLabel>

      </Step>

      <Step >
        <StepLabel>Programme de formation et partage des coûts</StepLabel>

      </Step>
      {/* Repeat the above Step and StepContent for each step */}
    </Stepper>
  );
};

export default CustomStepper;




