import React from 'react';
import img from '../../../../assets/why.jpg'
export default function Pourquoi() {
    return (
        <div className="w-11/12 flex my-12 gap-10 flex-col lg:flex-row justify-around items-center">
            {/* Image section */}
            <img 
                src={img} 
                alt="photos" 
                className="w-full lg:w-5/12 rounded-lg shadow-md"
            />

            {/* Text section */}
            <div className="flex flex-col gap-y-6 text-center lg:text-left">
                <span className="text-[#2C2F24] text-3xl lg:text-4xl font-semibold">
                Pourquoi choisir NATURA GERME ?
                </span>
                <p className="text-base leading-relaxed text-gray-700 flex flex-col gap-y-4">
                <span className='text-xl'>
                    <b className="text-xl text-[#2C2F24]">Personnalisation :</b> Recevez des recommandations de formation adaptées à vos besoins uniques.
                </span>
                <span className='text-xl'>
                    <b className="text-xl text-[#2C2F24]">Efficacité :</b> Optimisez votre apprentissage avec des sessions de formation ciblées.
                </span>
                <span className='text-xl'>
                    <b className="text-xl text-[#2C2F24]">Soutien expert :</b> Bénéficiez de l’accompagnement de formateurs qualifiés et expérimentés.
                </span>
                </p>
            </div>
            </div>

    )
}