import React from 'react';
import { Paper } from '@mui/material';
import "./style/slide.css";
// import image from "../../../../assets/Logo.png"
import image2 from "../../../../assets/TVM.png"
import image3 from "../../../../assets/haisoa.png"
import image4 from "../../../../assets/TORO.png"
import image5 from "../../../../assets/TSARA.png"
import { useNavigate } from 'react-router-dom';

const items = [
  // {
  //   name: "Random Name #1",
  //   image: image
  // },
  {
    name: "Random Name #1",
    image: image2,
    link: "https://www.tsenavitamalagasy.com"
  },
  {
    name: "Random Name #1",
    image: image3,
    link: "https://www.haisoa.com"
  },
  {
    name: "Random Name #1",
    image: image4,
    link: "https://www.toromadagascar.com"
  },
  {
    name: "Random Name #1",
    image: image5,
    link: "https://www.toromadagascar.com"
  },
];

const Partenaire = () => {

  return (
    <div className="flex flex-col gap-y-3 w-11/12 items-center">
      <h1 className='text-3xl text-slate-600 font-semibold'>
        Partenaires
      </h1>
      <div className="carousel-container">
        <div className="infinite-slide">
          {items.concat(items).map((item, i) => (
            <Paper key={i} className="carousel-item" sx={{ boxShadow: 0 }} >
              <a href={item.link} target="_blank" rel="noreferrer">
                <img src={item.image} className='h-[150px] w-[200px]'/>
              </a>
              {/* <p>{item.name}</p> */}
            </Paper>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Partenaire;