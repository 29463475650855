import React, { useEffect, useState } from 'react';
import user1 from '../../../assets/users/prestataire1.png';
import Header from '../../components/Header'
import Diplome from './partials/Diplome'
import axios from 'axios'
import { api } from '../../../api'
import userIcon from '../../../assets/images/icons8-test-account-96.png'
import Notification from '../../../entrepreneur/components/feedback/Notification'
import Modals from '../../../entrepreneur/components/modal'
import { Button } from '@mui/material'
import PasswordModal from '../../components/PasswordModal'
import { useOutletContext } from 'react-router-dom';

const Parametre = () => {
  // État pour gérer les informations de l'utilisateur
  const user_id = localStorage.getItem('naturagerme-id');
  const [userInfo, setUserInfo] = useState({
        "id": "",
        "nom": "",
        "prenom": "",
        "nomJeuneFille": "",
        "adressePostale": "",
        "tel": "",
        "pere": "",
        "mere": "",
        "sexe": "",
        "dateNaissance": "",
        "fax": "",
        "niveauFormation": "",
        "fonctionEntreprise": "",
        "pays": "",
  });

  const [userCompetence, setUserCompetence] = useState({
    "id": "",
    "maitriseDomaine": "",
    "experiencePratique": "",
    "techniqueTransmission": "",
    "capacite": "",
    "experienceProf": "",
    "qualitePerso": "",
    "user_id": user_id
});

// const [userCompetence, setUserCompetence] = useState([]);

  const [userProfile, setUserProfile] = useState([])
  const [isFormValid, setIsFormValid] = useState(false)
  const [imageSelected, setImageSelected] = useState(null)
  const [confirm, setConfirm] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [activeForm, setActiveForm] = useState(null)
  const {setTitle} = useOutletContext()
  const [isOpen, setIsOpen] = useState({
    success: false,
    fails: false
  })

  const getProfileFormateur = () => {

    axios.get(api + `/api/getProfilFormateur/${user_id}`)
    .then(response =>{
      setUserInfo(response.data.donnee_perso1);
      setUserProfile(response.data)
    })
    .catch(error => {

    })
  }

  const getCompetenceFormateur = () => {
    axios.get(api + `/api/getCompetenceFormateur/${user_id}`)
    .then(response =>{

      if (response.data.competences[0])
      {
        setUserCompetence(response.data.competences[0]);
      }
    })
    .catch(error => {
    })
  }

  const handleInfoSubmit = (formId) => {
    setActiveForm(formId);
    setIsModalOpen(true);
  }

  const confirmModification = () => {
    if (activeForm === 'personalInfo') {
      axios.post(api + `/api/modDonneePerso1/${user_id}`, userInfo)
        .then(response => {
          setIsModalOpen(false)
          setIsOpen({...isOpen, success: true })
          console.log("Données modifiées avec succès");
        })
        .catch(error => {
          setIsModalOpen(false)
          setIsOpen({...isOpen, fails: true })
          console.error("Erreur lors de la modification des données", error);
        });
    } else if (activeForm === 'competence') {
      if (userCompetence.id !== "")
        {
          axios.post(api + `/api/modCompetence/${userCompetence.id}`, userCompetence)
            .then(response =>{
              setIsModalOpen(false)
              setIsOpen({...isOpen, success: true })
              console.log("Modification avec succès")
    
            })
            .catch(error => {
              setIsOpen({...isOpen, fails: true })
            })
        
        }
    
        else{
          axios.post(api + '/api/ajoutCompetence', userCompetence)
            .then(response =>{
              setIsModalOpen(false)
              setIsOpen({...isOpen, success: true })
              console.log("Ajout avec succès")
    
            })
            .catch(error => {
              setIsOpen({...isOpen, fails: true })
            })
        }



      // if (userCompetence.id !== "")
      //   {
      // axios.post(api + `/api/modCompetence`, userCompetence)
      //   .then(response => {
      //     setIsModalOpen(false)
      //     setIsOpen({...isOpen, success: true })
      //     console.log("Compétences modifiées avec succès");
      //   })
      //   .catch(error => {
      //     setIsModalOpen(false)
      //     setIsOpen({...isOpen, success: false })
      //     console.error("Erreur lors de la modification des compétences", error);
      //   });
      // }
    }
    // setIsModalOpen(false);
  };

  const cancelModification = () => {
    setIsModalOpen(false);
  };



  useEffect(() => {
    setTitle('Paramètre')
    
    getProfileFormateur();
    getCompetenceFormateur();

  }, [setTitle])
  


  // État pour gérer le mot de passe
  const [passwords, setPasswords] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  // Fonction pour gérer les changements dans les champs de texte
  const handleChange = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  }

  const handleCompetenceChange = (e) => {
    setUserCompetence({
      ...userCompetence,
      [e.target.name]: e.target.value,
    });
  };

  // Fonction pour gérer les changements dans les champs de mot de passe
  const handlePasswordChange = (e) => {
    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value,
    });
  };

  // Fonction pour soumettre les modifications d'informations
  // const handleInfoSubmit = () => {

  //   axios.post(api + `/api/modDonneePerso1`, userInfo)
  //   .then(response =>{
  //     // console.log('Données modifiées')
  //     getProfileFormateur();

  //   })
  //   .catch(error => {

  //   })
  // }

  // Ajout ou modification competences
  const handleCompetenceSubmit = () => {

    if (userCompetence.id !== "")
    {
      axios.post(api + `/api/modCompetence/${userCompetence.id}`, userCompetence)
        .then(response =>{
          // console.log('Données modifiées')
          // getProfileFormateur();
          getCompetenceFormateur();
          console.log("Modification avec succès")

        })
        .catch(error => {

        })
    
    }

    else{
      axios.post(api + '/api/ajoutCompetence', userCompetence)
        .then(response =>{
          getCompetenceFormateur();
          console.log("Ajout avec succès")

        })
        .catch(error => {

        })
    }
  }

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const changePhoto = (sectionId) => {
    const formData = new FormData()
        formData.append("photo", imageSelected);
        formData.append("id", user_id);
    axios.post(api + '/api/modifierImage', formData)
    .then(response =>{
      setIsOpen({...isOpen, success: true })
      // console.log(response.data.data.nom)
      localStorage.setItem("naturagerme-photo", response.data.data.photo);

    })
    .catch(error => {
      setIsOpen({...isOpen, fails: true })
    })
  }

  return (
    <>
      {/* <Header title="Paramètre" /> */}
      <div className='border-line mx-4 lg:mx-0 p-6 mt-6 min-h-[570px]'>
        <div className="grid grid-cols-1 md:grid-cols-5 gap-3">
        <div className="col-span-3">
  {/* <div className="border-line px-10 py-5 text-start gap-y-5 sticky top-10  max-w-xs lg:block hidden ">
    <ul className="flex-col flex gap-6 font-medium menu-list ">
              <li onClick={() => scrollToSection('skills')}>Compétence</li>
              <li onClick={() => scrollToSection('diplomas')}>Diplôme & Certificat</li>
              <li onClick={() => scrollToSection('password')}>Modifier le mot de passe</li>
              </ul>
            </div> */}

              <div className='border-line p-4 mb-4' id="personal-info">
              <h5 className='text-lg mb-4 text-start'>Information générale</h5>
              {/* <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'> */}
                <div className='input-form'>
                  <label htmlFor="nom">Nom</label>
                  <input
                    type="text"
                    id="nom"
                    name="nom"
                    className="input"
                    value={userProfile?.nom}
                    onChange={handleChange}
                  />
                </div>

              <div className='  gap-4'>
                 <div className='input-form'>
                  <label htmlFor="contact">E-mail</label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    className="input"
                    value={userProfile?.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* <div className='flex justify-end items-end mt-6'>
                <button className='btn-black' onClick={() => handleInfoSubmit('personalInfo')}>Modifier</button>
              </div> */}
            </div>

                        {/* Formulaire de compétence */}
                        <div className='border-line p-4 mb-4'  id="skills">
              <h5 className='text-lg mb-4 text-start'>Compétences</h5>
              <div className='input-form'>
                <label htmlFor="maitrise">Maîtrise du domaine</label>
                <textarea
                  id="maitriseDomaine"
                  name="maitriseDomaine"
                  className="input-textarea"
                  // placeholder='Ajouter des maîtrise de domaines'
                  // value={userCompetence.maitriseDomaine}
                  value={userCompetence.maitriseDomaine}
                  onChange={handleCompetenceChange}
                />
                <input
                  id="id"
                  name="id"
                  className="input hidden"
                  // placeholder='Ajouter des maîtrise de domaines'
                  // value={userCompetence.maitriseDomaine}
                  value={userCompetence.id}
                  onChange={handleCompetenceChange}
                />
                <input
                  id="id"
                  name="id"
                  className="input hidden"
                  // placeholder='Ajouter des maîtrise de domaines'
                  // value={userCompetence.maitriseDomaine}
                  value={userCompetence.user_id}
                  onChange={handleCompetenceChange}
                />
              </div>
              <div className='input-form'>
                <label htmlFor="experience">Expérience pratique</label>
                <textarea
                  id="experiencePratique"
                  name="experiencePratique"
                  className="input-textarea "
                  placeholder='Ajouter des expériences'
                  value={userCompetence.experiencePratique}
                  onChange={handleCompetenceChange}
                />
              </div>
              <div className='input-form'>
                <label htmlFor="qualite">Qualité</label>
                <textarea
                  id="experiencePratique"
                  name="qualitePerso"
                  className="input-textarea"
                  placeholder='Ajouter une description'
                  value={userCompetence.qualitePerso}
                  onChange={handleCompetenceChange}
                />
              </div>
              {/* <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'> */}
                <div className='input-form'>
                  <label htmlFor="techniqueTransmission">Technique de transmission</label>
                  <textarea
                    type="text"
                    id="techniqueTransmission"
                    name="techniqueTransmission"
                    className="input-textarea"
                    value={userCompetence.techniqueTransmission}
                    onChange={handleCompetenceChange}
                  />
                </div>
                <div className='input-form'>
                  <label htmlFor="capacite">Capacité de communication</label>
                  <textarea
                    type="text"
                    id="capacite"
                    name="capacite"
                    className="input-textarea"
                    value={userCompetence.capacite}
                    onChange={handleCompetenceChange}
                  />
                </div>
              <div className='flex justify-end items-end mt-6'>
                <button className='btn-black' onClick={()=>handleInfoSubmit('competence')}>Modifier</button>
              </div>
            </div>

            {/* Formulaire d'ajout d'importation de diplôme ou certificat */}
            <div id='diplomas'>    
              <Diplome/>
            </div>
        

            {/* Formulaire de modification du mot de passe */}
            <div className='border-line p-4 mb-4' id="password">
              <h5 className='text-lg mb-4 text-start'>Modifier le mot de passe</h5>
              <div className='flex justify-end items-end mt-6'>
                <button className='btn-black' onClick={()=>setIsPasswordModalOpen(true)}>Modifier</button>
              </div>
            </div>

          </div>

          <div className="col-span-2">
            {/* Information personnelle */}
            {/* <div className='border-line p-4 mb-4' id='base-info'>
              <div className="flex gap-4">
                <img 
                src={api + '/storage/' + userProfile?.photo} alt="..." 
                className='w-16 h-16 rounded-full'
                onError={(e) => { e.target.onerror = null; e.target.src = userIcon; }} />
                <div className="flex flex-col justify-between gap-2 items-start">

                  <p className='text-xl text-black-1'>{userProfile?.nom}</p>

                  <p>{userProfile?.email}</p>
                </div>
              </div>
            </div> */}
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">

                <h3 className="font-medium text-black dark:text-white">
                  Votre photo de profil

                </h3>
              </div>
              <div className="p-7">
                <form action="#">
                  <div className="mb-4 flex items-center gap-3">
                    {/* <div className="h-14 w-14 rounded-full"> */}
                    <img 
                src={api + '/storage/' + userProfile?.photo} alt="..." 
                className='min-w-16 h-16 rounded-full'
                onError={(e) => { e.target.onerror = null; e.target.src = userIcon; }} />
                    {/* </div> */}
                    <div className="flex flex-col justify-between gap-1 items-start">

<p className='text-xl text-black-1'>{userProfile?.nom}</p>

<p>{userProfile?.email}</p>
</div>
                  </div>

                  <div
                    id="FileUpload"
                    className="relative mb-5.5 block w-full cursor-pointer appearance-none rounded border border-dashed border-primary bg-gray py-4 px-4 dark:bg-meta-4 sm:py-7.5"
                  >
                    <input
                      type="file"
                      accept="image/*"
                      className="absolute inset-0 z-50 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
                      onChange={(e)=>setImageSelected(e.target.files[0])}
                    />
                    <div className="flex flex-col items-center justify-center space-y-3">
                      <span className="flex h-10 w-10 items-center justify-center rounded-full border border-stroke bg-white dark:border-strokedark dark:bg-boxdark">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.99967 9.33337C2.36786 9.33337 2.66634 9.63185 2.66634 10V12.6667C2.66634 12.8435 2.73658 13.0131 2.8616 13.1381C2.98663 13.2631 3.1562 13.3334 3.33301 13.3334H12.6663C12.8431 13.3334 13.0127 13.2631 13.1377 13.1381C13.2628 13.0131 13.333 12.8435 13.333 12.6667V10C13.333 9.63185 13.6315 9.33337 13.9997 9.33337C14.3679 9.33337 14.6663 9.63185 14.6663 10V12.6667C14.6663 13.1971 14.4556 13.7058 14.0806 14.0809C13.7055 14.456 13.1968 14.6667 12.6663 14.6667H3.33301C2.80257 14.6667 2.29387 14.456 1.91879 14.0809C1.54372 13.7058 1.33301 13.1971 1.33301 12.6667V10C1.33301 9.63185 1.63148 9.33337 1.99967 9.33337Z"
                            fill="#3C50E0"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.5286 1.52864C7.78894 1.26829 8.21106 1.26829 8.4714 1.52864L11.8047 4.86197C12.0651 5.12232 12.0651 5.54443 11.8047 5.80478C11.5444 6.06513 11.1223 6.06513 10.8619 5.80478L8 2.94285L5.13807 5.80478C4.87772 6.06513 4.45561 6.06513 4.19526 5.80478C3.93491 5.54443 3.93491 5.12232 4.19526 4.86197L7.5286 1.52864Z"
                            fill="#3C50E0"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.99967 1.33337C8.36786 1.33337 8.66634 1.63185 8.66634 2.00004V10C8.66634 10.3682 8.36786 10.6667 7.99967 10.6667C7.63148 10.6667 7.33301 10.3682 7.33301 10V2.00004C7.33301 1.63185 7.63148 1.33337 7.99967 1.33337Z"
                            fill="#3C50E0"
                          />
                        </svg>
                      </span>
                      <p>
                        <span className="text-primary">Cliquer ici</span> ou Glisser pour ajouter une image
                      </p>
                      <p className="mt-1.5">SVG, PNG, JPG ou GIF</p>
                      <p>(max, 800 X 800px)</p>
                    </div>
                  </div>

                  <div className="flex justify-end gap-4.5">
                    {/* <button
                      className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 dark:border-strokedark dark:text-white"
                      type="submit"
                    >
                      Cancel
                    </button> */}
                    <button
                    onClick={changePhoto}
                      className="flex justify-center rounded btn-black py-2 px-6 font-medium text-gray hover:bg-opacity-90 mt-4"
                      type="submit"
                    >
                      Modifier
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modals 
        isOpen={isModalOpen}
        onRequestClose={cancelModification}>
            <div className="sm:flex sm:items-start">
                <div
                    className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg className="h-6 w-6 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium" id="modal-headline">
                        Confirmation
                    </h3>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            Voulez-vous enregistrer vos modifications ?
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                // onClick={handleSubmit}
                onClick={confirmModification}
                type="button" data-behavior="commit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">
                    Enregister
                </button>
                <button
                onClick={cancelModification}
                type="button" data-behavior="cancel" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-50 sm:mt-0 sm:w-auto sm:text-sm">
                    Annuler
                </button>
            </div>
            </Modals>
            <PasswordModal isOpen={isPasswordModalOpen} onClose={()=>setIsPasswordModalOpen(false)} />
            <Notification type="success" message="Modification réussi." isOpen={isOpen.success} onClose={() => { setIsOpen({ ...isOpen, success: false }) }} />
            <Notification type="error" message="Une erreur s'est produite." isOpen={isOpen.fails} onClose={() => { setIsOpen({ ...isOpen, fails: false }) }} />

            {/* <Notification type="success" message="Modification du mot de passe réussi." isOpen={isOpen2.success} onClose={() => { setIsOpen2({ ...isOpen2, success: false }) }} /> */}
    </>
  );
};

export default Parametre;
