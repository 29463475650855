import { Stepper, Step, StepLabel, StepContent } from '@mui/material';
import React from 'react';

export default function StepperAuth({ activeStep }) {
    return (
        <>
            <div className="max-lg:hidden">
                <Stepper activeStep={activeStep} orientation='vertical'>
                    <Step>
                        <StepLabel>
                            Création de compte
                        </StepLabel>
                        <StepContent>

                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>
                            Vérification de compte
                        </StepLabel>
                        <StepContent>

                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>
                            Questionnaire
                        </StepLabel>
                        <StepContent>
                            <div className='flex flex-col justify-start text-slate-300'>
                                <span>-Données personnelles</span>
                                <span>-Données rélative à l'entreprise</span>
                            </div>
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>
                            Formation desirées
                        </StepLabel>
                    </Step>
                </Stepper>
            </div>
            <div className="lg:hidden">
                <Stepper activeStep={activeStep} >
                    <Step>
                        <StepLabel>

                        </StepLabel>

                    </Step>
                    <Step>
                        <StepLabel>
                        </StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>
                        </StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>
                        </StepLabel>
                    </Step>
                </Stepper>
            </div>
        </>
    )
}