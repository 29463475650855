import React, { useState } from 'react'
import Input from '../../entrepreneur/components/form/Input'
import Select from '../../entrepreneur/components/form/select'
import { Button } from '@mui/material'
import { useOutletContext } from 'react-router-dom'
import axios from 'axios'
import { api } from '../../api'
import Load from '../../entrepreneur/components/load'
import Notification from '../../entrepreneur/components/feedback/Notification'
import ToLogin from '../../entrepreneur/components/toLogin';

// import Button from '../../entrepreneur/components/btn/Button'

export default function Personnel2({ setPage }) {
    const { data, setData } = useOutletContext()
    const { bool, setBool } = useState(false)
    const [options, setOptions] = useState({
        loading: false,
        disabled: false
    })
    const [isOpen, setIsOpen] = useState({
        succcess: false,
        fails: false
    })
    function next() {

        setOptions({ ...options, loading: true })
        // setPage(prev => prev += 1)
        try {
            const form2 = new FormData()
            form2.append("langueMaternelle", data.langue_maternelle)
            form2.append("ecrireMaternelle", data.ecrire_dans_cette_langue)
            form2.append("langueFrs", data.maitrise_langue_francaise)
            form2.append("ecrireFrs",data.ecrire_en_francais)
            form2.append("formGest", data.formGest)
            form2.append("nomFormGest", data.laquelleFormGest)
            form2.append("dureeFormGest", data.dureeFormGest)
            form2.append("anneeExpGest", data.expFormGest)
            form2.append("formationProf", data.autre_formation_professionnel)
            form2.append("outilInfoGerme", data.informer_par)
            form2.append("experienceAffaire", data.experience_dans_les_affaires)
            form2.append("montantFormation", data.montant)
            form2.append("horaireFormation", data.horaire)
            form2.append("user_id", localStorage.getItem("naturagerme-id"))


            axios.post(api + "/api/ajoutDonneePerso2", form2)
                .then(res => {
                    console.log(res.data)
                    setPage(prev => prev += 1)
        setOptions({...options,loading: false})

                })
                .catch(err => {
                    console.error(err)
        setOptions({...options,loading: false})
        setIsOpen({...isOpen,fails: true})


                })
        } catch (error) {
            console.error(error)
        setIsOpen({...isOpen,fails: true})
        }
    }

    return (
        <>
            <form onSubmit={(e) => { e.preventDefault(); next() }} className="w-11/12 flex flex-col gap-y-2">
                <span className="font-black text-3xl">
                    Données personnels
                </span>
                <div className="w-full flex flex-row justify-between items-center gap-x-3">
                    <Select title={"Avez-vous déjà suivi une formation en gestion?"} values={["Oui", "Non"]} onChange={(e) => { setData({ ...data, formGest: e.target.value == "Oui" ? 1 : 0 }) }} />
                </div>
                <div className="w-full flex flex-row justify-between items-center gap-x-3">
                    <Input title={"Laquelle ?"} value={data.laquelleFormGest} onChange={(e) => { setData({ ...data, laquelleFormGest: e.target.value }) }} />
                    <Input title={"Durée ?"} value={data.dureeFormGest} onChange={(e) => { setData({ ...data, dureeFormGest: e.target.value }) }} />
                </div>
                <div className="w-full flex flex-row justify-between items-center gap-x-3">
                    <Input title={"Combien d’années d’expérience avez-vous en gestion d'entreprises ?"} value={data.expFormGest} onChange={(e) => { setData({ ...data, expFormGest: e.target.value }) }} />
                </div>
                <div className="w-full flex flex-row justify-between items-center gap-x-3">
                    <Input title={"Quelle(s) autre(s) formation(s) professionnelle(s) avez-vous suivi ?"} value={data.autre_formation_professionnel} onChange={(e) => { setData({ ...data, autre_formation_professionnel: e.target.value }) }} />
                    <Select title={"Qui vous a informer  sur les formations GERME ?"} values={["La television", "Le journal", "Le radio", "Un ami", "une banderole", "Une affiche", "autre"]} onChange={(e) => { setData({ ...data, informer_par: e.target.value }) }} />
                </div>
                <div className="w-full flex flex-row justify-between items-center gap-x-3">
                    <Input title={"Quel montant pourriez vous payer pour suivre la formation?"} value={data.montant} type='number' onChange={(e) => { setData({ ...data, montant: e.target.value }) }} />
                    {/* <Select title={"Quel horaire de formation vous conviendrait le mieux ?"} values={["En journée continu", "En soirée", "Le matin", "L'après midi", "Le week-end", "Sans avis"]} onChange={(e)=>{setData({...data,horaire:e.target.value})}}/> */}
                </div>
                <div className="w-full flex flex-row justify-between items-center gap-x-3">
                    <Input title={"Quelles sont vos propres expériences dans les affaires"} value={data.experience_dans_les_affaires} onChange={(e) => { setData({ ...data, experience_dans_les_affaires: e.target.value }) }} />
                    <Select title={"Quel horaire de formation vous conviendrait le mieux ?"} values={["En journée continu", "En soirée", "Le matin", "L'après midi", "Le week-end", "Sans avis"]} onChange={(e) => { setData({ ...data, horaire: e.target.value }) }} />
                </div>


                <div className="w-full flex max-lg:flex-col-reverse gap-y-2 justify-between item-center">
                <ToLogin/>
                    <Button type='submit' disabled={options.disabled} sx={{
                        bgcolor: "#1e293b",
                        color: "white",
                        "&:hover": {
                            backgroundColor: "#1e293b", // Change the hover background color
                            color: "white", // Change the hover text color
                        },
                    }} variant='contained' className="px-9 ">
                        <Load title={"Suivant"} loading={options.loading} next={true} />
                    </Button>

                </div>
            </form>
            <Notification type="error" message="Une erreur s'est produite." isOpen={isOpen.fails} onClose={() => { setIsOpen({ ...isOpen, fails: false }) }} />

        </>
    )
}