import React, { useEffect } from 'react';
import Select from '../../Select'
import { selectInducateur } from '../../../../data';
import { useOutletContext } from 'react-router-dom';
import { Button } from '@mui/material';
export default function Indicateur1({ setPage }) {
    const { data, setData } = useOutletContext()
    function handleSubmit(e) {
        e.preventDefault()
        setPage(prev => prev += 1)
    }
    useEffect(()=>{
        console.log(data.entrepriseFamille1)
    },[data])
    return (
        <>
            <form onSubmit={handleSubmit} className='flex flex-col justify-start mb-2 items-center gap-y-2 w-full'>
                <span className="text-xl font-bold w-full text-left"> Entreprise et Famille</span>
                <Select title={"Verse un salaire au propriétaire / gérant"} values={selectInducateur} onChange={(e) => {console.log(e.target.value); setData({ ...data, entrepriseFamille1: Number(e.target.value) }) }} />

                <Select title={"Verse un salaire aux membres de la famille qui y travaillent"} values={selectInducateur} onChange={(e) => { setData({ ...data, entrepriseFamille2: Number(e.target.value) }) }} />
                <Select title={"Ne prend pas d’argent dans la caisse sans reçu"} values={selectInducateur} onChange={(e) => { setData({ ...data, entrepriseFamille3: Number(e.target.value) }) }} />

                <div className='w-full flex flex-row justify-end items-center'>
                    <Button variant='contained' type='submit' style={{ backgroundColor: 'slategray' }}>
                        Suivant
                    </Button>
                </div>
            </form>
        </>
    )

}
