import React, { useState } from 'react';
// import {} from '@fortawesome/fontawesome-svg-core'
import img from './../../../assets/Logo.png'
import List from './header_item';
// import Button from '../btn/Button';

import { useNavigate } from 'react-router-dom';
import { IoBag, IoCard, IoHelp, IoHome, IoLogIn, IoLogOut, IoMenu, IoPerson } from 'react-icons/io5';
import { Box, Button, Drawer } from '@mui/material';
import Modals from '../modal';
import { logout } from '../../../script/logout';
import { FaExclamationCircle } from 'react-icons/fa';

export default function Header() {
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)
    const [confirm, setConfirm] = useState(false)

    return (
        <>
            <div className="w-11/12 flex flex-row justify-between items-center">
                <div className="flex flex-row justify-between items-center gap-x-3 w-full">
                    <img src={img} alt='logo' className='w-[60px] h-[70px]' onClick={() => { navigate("/") }} />
                    <div className='flex justify-end items-center flex-row gap-x-3 max-lg:hidden'>
                        <List />
                        {/* <Button title="Se déconnecter" type='outline'/> */}
                        {
                            localStorage.getItem('naturagerme-id') ? (
                                <Button color='error' variant='outlined' size="small" className='flex flex-row gap-x-1' onClick={() => { setConfirm(true) }} >
                                    <span className='capitalize'>Se</span>
                                    <span className='lowercase'>déconnecter</span>
                                </Button>
                            ) : (
                                <>
                                    <Button variant="contained" size="small" className='flex flex-row gap-x-1' style={{ backgroundColor: "#1e293b" }} onClick={() => { navigate('/Login') }} >
                                        <span className='capitalize text-lg'>Se</span>
                                        <span className='lowercase text-lg'>connecter</span>
                                    </Button>
                                    {/* <Button variant='outlined' size="small" onClick={() => { navigate('/Signup') }} >S'inscrire</Button> */}
                                </>
                            )
                        }

                    </div>
                    <span className="lg:hidden">
                        <Button variant='text' onClick={() => { setIsOpen(true) }}>
                            <IoMenu className='text-xl' />
                        </Button>
                    </span>
                </div>
            </div>
            <Drawer open={isOpen} onClose={() => { setIsOpen(false) }}>
                <Box sx={{ width: 300 }}>
                    <div className='w-full p-4'>
                        <ul className="flex w-full flex-col gap-y-3">
                            <li>
                                <img src={img} alt='logo' className='w-[70px] h-[80px]' />
                            </li>
                            <li className='flex flex-row hover:cursor-pointer hover:bg-slate-50 py-2 gap-x-4 justify-start items-center text-slate-500' onClick={() => { navigate('/') }}>
                                <IoHome />
                                <span >Accueil</span>
                            </li>
                            <li className='flex flex-row  hover:cursor-pointer gap-x-4 justify-start items-center cursor-pointer hover:bg-slate-50 py-2 text-slate-500' onClick={() => { navigate('/Formation') }}>
                                <IoBag />
                                <span>Formation</span>
                            </li>
                            {
                                localStorage.getItem('naturagerme-id') && (
                                    <li className='flex flex-row  hover:cursor-pointer gap-x-4 justify-start items-center text-slate-500 cursor-pointer hover:bg-slate-50 py-2' onClick={() => { navigate('/Profile') }}>
                                        <IoPerson />
                                        <span>Profil</span>
                                    </li>
                                )
                            }
                            <li className='flex flex-row  hover:cursor-pointer gap-x-4 justify-start items-center cursor-pointer hover:bg-slate-50 py-2 text-slate-500' onClick={() => { navigate('/apropos') }}>
                                <FaExclamationCircle />
                                <span>A propos</span>
                            </li>
                            {
                                !localStorage.getItem('naturagerme-id') ? (
                                    <>
                                        <li className='flex flex-row gap-x-4 justify-start items-center text-slate-500 cursor-pointer hover:bg-slate-50 py-2' onClick={() => { navigate('/Login') }}>
                                            <IoLogIn />
                                            <span>Se connecter</span>
                                        </li>
                                        {/* <li className='flex flex-row gap-x-4 justify-start items-center text-slate-500 cursor-pointer hover:bg-slate-50 py-2' onClick={() => { navigate('/Signup') }}>
                                            <IoCard />
                                            <span>S'insccrire</span>
                                        </li> */}
                                    </>
                                ) : (
                                    <li className='flex flex-row gap-x-4 justify-start items-center text-slate-500 cursor-pointer hover:bg-slate-50 py-2' onClick={() => {
                                        setConfirm(true)
                                    }}>
                                        <IoLogOut />
                                        <span>Se deconnecter</span>
                                    </li>
                                )
                            }

                            {/* <li>Contact</li> */}
                        </ul>
                    </div>
                </Box>
            </Drawer>
            <Modals isOpen={confirm} onClose={() => { setConfirm(false) }}>
                <h4>Confirmation de deconnexion</h4>
                <p className='my-2'>
                    Êtes-vous sûr de vouloir vous déconnecter ?
                </p>
                <div className='w-full flex flex-row  gap-x-4 justify-between items-center'>
                    <Button
                        variant="outlined"
                        size="small"
                        fullWidth
                        onClick={() => { setConfirm(false) }}
                    >
                        Annuler
                    </Button>
                    <Button
                        fullWidth
                        onClick={() => { setConfirm(false); logout(); navigate('/Login') }}
                        size="small"
                        color='error' variant='contained' className="px-9 "
                    >
                        Confirmer
                    </Button>
                </div>
            </Modals>
        </>
    )
}