import React from 'react';

export default function Input({ required = false, value, onChange, placeholder, title, type = "text",wrong=false,min,error_message="" }) {
    return (
        <div className='flex flex-col justify-start items-start gap-y-1 w-full'>
           <div className='flex flex-row justify-between w-full items-center'>
           <label className='input_label'>{title}{
                required==true && (
                    <span className='text-red-400'> *</span>
                )}</label>
                {error_message && (
                    <span className='text-red-400'>{error_message}</span>
                )}
           </div>
            <input type={type} min={min} required={required} className={` w-full ${wrong?'border-2 border-red-500':'border-[#DDDDDD] border'}  rounded-[4px] h-10 p-1`} placeholder={placeholder} onChange={onChange} value={value} />
        </div>
    )
}