import React, { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import axios from 'axios';
import { api } from '../../../api'
import Header from '../../components/Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import Formation from '../../../entrepreneur/pages/Formation'
import ImageWithFallback from '../../components/ImageWithFallback';
import Notification from '../../../entrepreneur/components/feedback/Notification';

const DetailFormation = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState({
    success: false,
    fails: false
})
  const [formValues, setFormValues] = useState({
    id: '',
    titre: '',
    description: '',
    duree: '',
    dateDebut: '',
    dateFin: '',
    lieu: '',
    photo: '',
    organisation: '',
    user_id: '',
  });

  const [dataFormation, setDataFormation] = useState({
    id: 'id',
    titre: '',
    description: '',
    duree: '',
    dateDebut: '',
    dateFin: '',
    lieu: '',
    photo: '',
    organisation: '',
    user_id: '',
  });

  const [formateurs, setFormateurs] = useState([])
  const [trainers, setTrainers] = useState([]);
  const [selectedTrainer, setSelectedTrainer] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false)
  const {setTitle} = useOutletContext()
  const [availableTrainers, setAvailableTrainers] = useState([])

  const getDetailFormation = () => {

      axios.get(api+`/api/getDetailFormation/${id}`)
        .then(response => {
          console.log(response.data[0])
          setDataFormation(response.data[0])
          setAvailableTrainers(response.data[1])
        })
        .catch(error => {
          console.error("Erreur lors de la récupération des formations:", error);
        })
  
  }


  const getAllFormateursFormation = () => {

    axios.get(api + '/api/getAllFormateurFormation/' + id)
    .then(response =>{
      setTrainers(response.data);
    })
    .catch(error => {

    })
  }

  const getAllFormateurs = () => {

    axios.get(api + '/api/getAllFormateur')
    .then(response =>{
      // console.log('Données modifiées')
      // getProfileFormateur();
      setAvailableTrainers(response.data);
      console.log(availableTrainers);

    })
    .catch(error => {

    })
  }

  useEffect(() => {
    setTitle("Formation")
  
    getDetailFormation()
    getAllFormateursFormation()
    getAllFormateurs()

  
  }, [setTitle])
  

  // useEffect(() => {
  //   // Fetch existing training data
  //   const fetchTraining = async () => {
  //     try {
  //       const response = await axios.get(api+`/getDetailFormation/${id}`);
  //       const training = response.data;
  //       console.log(response.data)
  //       setFormValues({
  //         titre: training.titre,
  //         description: training.description,
  //         duree: training.duree,
  //         dateDebut: training.dateDebut,
  //         dateFin: training.dateFin,
  //         lieu: training.lieu,
  //         photo: '',
  //         organisation: training.organisation,
  //         user_id: training.user_id,
  //       });
  //       setTrainers(training.trainers || []);
  //     } catch (error) {
  //       console.log('Erreur lors du chargement de la formation:', error);
  //       // alert('Une erreur s\'est produite lors du chargement de la formation.');
  //     }
  //   };

  //   fetchTraining();
  // }, [id]);

  // const handleInputChange = (e) => {
  //   const { titre, value } = e.target;
  //   setDataFormation({ ...dataFormation, [titre]: value });
  // };

  const handleInputChange = (e) => {
    setDataFormation({
      ...dataFormation,
      [e.target.name]: e.target.value,
    });
  }

  const handleFileChange = (e) => {
    setDataFormation({ ...dataFormation, photo: e.target.files[0] });
  }

  const removeTrainer = async (trainerId) => {
    const formData = new FormData();
    formData.append('user_id', trainerId);
    formData.append('formation_id', id);

    console.log('formation_id:', id)
    console.log('user_id:', trainerId)

    await axios.post(`${api}/api/supprimerFormateur`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Indique que vous envoyez des données multipart
      },
    })
    .then((response)=>{
      // const updatedTrainers = trainers.filter((_, i) => i !== id);
      // setTrainers(updatedTrainers);
      getAllFormateursFormation()
    })  
    .catch((error)=>{
      console.error('Erreur lors de la modification de la formation:', error);
    })

  };

  const handleSubmit = async () => {
      const formData = new FormData();
      formData.append('id', dataFormation.id);
      formData.append('titre', dataFormation.titre);
      formData.append('description', dataFormation.description);
      formData.append('duree', dataFormation.duree);
      formData.append('dateDebut', dataFormation.dateDebut);
      formData.append('dateFin', dataFormation.dateFin);
      formData.append('lieu', dataFormation.lieu);
      formData.append('organisation', dataFormation.organisation);
  
      // Ajout conditionnel de la photo
      if (dataFormation.photo) {
        formData.append('photo', dataFormation.photo);
      }
  
      // Envoi des données avec axios
      const response = await axios.post(`${api}/api/modFormation/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Indique que vous envoyez des données multipart
        },
      })
      .then((response)=>{
        // Succès
        console.log('Formation modifiée avec succès!', response.data);
        setIsOpen({ ...isOpen, success: true })
        setTimeout(() => {
          navigate('/formateur/formation')
        }, 500);
      })  
      .catch((error)=>{
        console.error('Erreur lors de la modification de la formation:', error);
        setIsOpen({ ...isOpen, success: false })
      })
  };

  const addTrainer = async () => {
    if (selectedTrainer && !trainers.some(t => t.id.toString() === selectedTrainer)) {

      const formData = new FormData();
      formData.append('user_id', selectedTrainer);
      formData.append('formation_id', id);
      await axios.post(`${api}/api/ajoutFormateur`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Indique que vous envoyez des données multipart
        },
      })
      .then((response)=>{
        const trainerObj = availableTrainers.find(t => t.id.toString() === selectedTrainer)
        setTrainers([...trainers, trainerObj])
        setSelectedTrainer('')
        setIsModalOpen(false)
        setIsOpen({ ...isOpen, success: true });
      })  
      .catch((error)=>{
        console.error('Erreur lors de la modification de la formation:', error);
        setIsOpen({ ...isOpen, success: false });
      })

    
    }
  }
  
  return (
    <>
      <div className='border-line mx-4 lg:mx-0 p-6 mt-6 min-h-[570px]'>
        <div className='flex w-full justify-between items-center mb-6'>
        <div className="flex items-center gap-2 ">
          <button className={"  border  rounded-full p-2 w-[34px] "} onClick={()=>navigate('/formateur/formation')} >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <h1 className='text-xl font-semibold'>Modifier la formation</h1>
      </div>
          
         
        </div>

        <div className="flex gap-10 min-h-screen">
  {/* Colonne Sticky */}
  <div className=" hidden lg:block w-1/3">
    <div className="sticky top-10 p-5 border border-line">
      <ul className="flex flex-col gap-6">
        <li>Information générale</li>
        <li>Formateur</li>
      </ul>
    </div>
   
  </div>


  {/* <!-- Colonne Droite avec Contenu Long --> */}
  <div className="w-full lg:w-2/3 space-y-10">
     {/* Formateur */}
     <div className='border-line p-4 mb-4 '>
              <div className='flex justify-between'>
                <h5 className='text-lg font-medium mb-4 text-start'>Formateur</h5>
                <button className='btn-black' onClick={() => setIsModalOpen(true)}>Ajouter</button>
              </div>

              <div className='mt-4'>
              {trainers?.map((trainer, index) => (
                <div key={index} className='input-form flex justify-between items-center my-2 border rounded-md px-4 border-black-1/20 py-4 bg-gray-50'>
                  
                  <div className="flex items-center gap-2">
                  <ImageWithFallback src={api+"/storage/"+trainer?.photo} alt="" className='size-12 rounded-full' defaultType='user'/>
                  <p>{trainer?.nom}</p>
                    </div>
                  <button className='btn-red text-red-600' onClick={() => removeTrainer(trainer?.id)}>Supprimer</button>
                </div>
              ))}
              </div>

              {/* Modal for Adding Trainer */}
              {isModalOpen && (
                <div className='modal'>
                  <div className='modal-content'>
                    <h5 className='text-lg font-medium mb-4'>Ajouter un Formateur</h5>
                    <select
                      value={selectedTrainer}
                      onChange={(e) => setSelectedTrainer(e.target.value)}
                      className='input mb-4'
                    >
                      <option value="" disabled>Choisir un formateur</option>
                      {availableTrainers.map((trainer, index) => (
                        <option key={index} value={trainer.id}>{trainer.nom}</option>
                      ))}
                    </select>

                    <div className='flex justify-end mt-4'>
                      <button className='btn-gray' onClick={() => setIsModalOpen(false)}>Annuler</button>
                      <button className='btn-black ml-2' onClick={addTrainer}>Ajouter</button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Information générale */}
            <div className='border-line p-4 mb-4'>
            <div className='flex justify-between items-center'>
              <h5 className='text-lg font-medium mb-4 text-start'>Information générale</h5>  
              <button className='btn-black' onClick={handleSubmit}>Enregistrer</button>
              </div>
              <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                <div className='input-form'>
                  <label htmlFor="titre">Titre</label>
                  <input
                    type="text"
                    id="titre"
                    name="titre"
                    className="input"
                    placeholder='Ecrire le titre'
                    value={dataFormation.titre}
                    onChange={handleInputChange}
                  />
                </div>
                <div className='input-form'>
                  <label htmlFor="duree">Durée</label>
                  <input
                    type="text"
                    id="duree"
                    name="duree"
                    className="input"
                    placeholder='Durée en minutes'
                    value={dataFormation.duree}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className='grid grid-cols-2 gap-4'>
                <div className='input-form'>
                  <label htmlFor="dateDebut" className="block text-sm font-medium text-gray-700 text-start">Date début</label>
                  <input
                    type="date"
                    id="dateDebut"
                    name="dateDebut"
                    className="input"
                    value={dataFormation.dateDebut}
                    onChange={handleInputChange}
                  />
                </div>
                <div className='input-form'>
                  <label htmlFor="dateFin" className="block text-sm font-medium text-gray-700 text-start">Date fin</label>
                  <input
                    type="date"
                    id="dateFin"
                    name="dateFin"
                    className="input"
                    value={dataFormation.dateFin}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className='input-form'>
                <label htmlFor="lieu" className="block text-sm font-medium text-gray-700 text-start">Lieu</label>
                <input
                  type="text"
                  id="lieu"
                  name="lieu"
                  className="input"
                  placeholder='Lieu de la formation'
                  value={dataFormation.lieu}
                  onChange={handleInputChange}
                />
              </div>
              <div className='input-form'>
                <label htmlFor="photo" className="block text-sm font-medium text-gray-700 text-start">Photo</label>
                <input
                  type="file"
                  id="photo"
                  name="photo"
                  className="input"
                  onChange={handleFileChange}
                />
              </div>
              <div className='input-form'>
                <label htmlFor="organisation" className="block text-sm font-medium text-gray-700 text-start">Organisation</label>
                <input
                  type="text"
                  id="organisation"
                  name="organisation"
                  className="input"
                  placeholder="Nom de l'organisation"
                  value={dataFormation.organisation}
                  onChange={handleInputChange}
                />
              </div>
              <div className='input-form'>
                <label htmlFor="user_id" className="text-sm font-medium text-gray-700 text-start hidden">User ID</label>
                <input
                  type="hidden"
                  id="user_id"
                  name="user_id"
                  className="input"
                  placeholder="ID de l'utilisateur"
                  value={dataFormation.user_id}
                  onChange={handleInputChange}
                />
              </div>
              <div className='input-form'>
                <label htmlFor="description" className="block text-sm font-medium text-gray-700 text-start">Description</label>
                <textarea
                  id="description"
                  name="description"
                  className="input h-80"
                  placeholder='Ajouter une description'
                  value={dataFormation.description}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <Notification type="success" message="Modification de formation réussie." isOpen={isOpen.success} onClose={() => { setIsOpen({ ...isOpen, success: false }) }} />
            <Notification type="error" message="Une erreur s'est produite." isOpen={isOpen.fails} onClose={() => { setIsOpen({ ...isOpen, fails: false }) }} />

            {/* Formateur */}
            {/* <div className='border-line p-4 mb-4'>
              <div className='flex justify-between'>
                <h5 className='text-lg font-medium mb-4 text-start'>Formateur</h5>
                <button className='btn-black' onClick={() => setIsModalOpen(true)}>Ajouter Formateur</button>
              </div>

              <div className='grid grid-cols-2 gap-4'>
                {trainers.map((trainer, index) => (
                  <div key={index} className='input-form flex justify-between items-center'>
                    <span>{trainer}</span>
                    <button className='btn-red' onClick={() => removeTrainer(index)}>Supprimer</button>
                  </div>
                ))}
              </div> */}

              {/* Modal for Adding Trainer */}
               {/* {isModalOpen && (
                <div className='modal'>
                  <div className='modal-content'>
                    <h5 className='text-lg font-medium mb-4'>Ajouter un Formateur</h5>
                    <select
                      value={selectedTrainer}
                      onChange={(e) => setSelectedTrainer(e.target.value)}
                      className='input mb-4'
                    >
                      <option value="" disabled>Choisir un formateur</option>
                      {trainers.map((trainer, index) => (
                        <option key={index} value={trainer}>{trainer}</option>
                      ))}
                    </select>
                    <div className='flex justify-end'>
                      <button className='btn-black mr-2' onClick={addTrainer}>Ajouter</button>
                      <button className='btn-red' onClick={() => setIsModalOpen(false)}>Annuler</button>
                    </div>
                  </div>
                </div>
              )} 
            </div> */}
            
          </div>
        </div>
      </div>
    </>
  )
}


export default DetailFormation

