import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DropdownMenu from '../../components/DropdownMenu';
import Header from '../../components/Header';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Modal from "../../components/Modal";
import { api } from "../../../api";
import icon1 from "../../../assets/icons/icon1.png";
import NoResults from '../../components/NoResults'
import formationIcon from '../../../assets/icons/icons8-education-96.png'
import Notification from '../../../entrepreneur/components/feedback/Notification';


const Formation = () => {
  const [formations, setFormations] = useState([]);
  const [loading, setLoading] = useState(true); // Nouvel état pour le chargement
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteFormationId, setDeleteFormationId] = useState(null)
  const navigate = useNavigate()
  const user_id = localStorage.getItem('naturagerme-id');
  const {setTitle} = useOutletContext()
  const [isOpen, setIsOpen] = useState({
    success: false,
    fails: false
})

  useEffect(() => {
    setTitle("Formation")
    axios.get(api + `/api/getAllFormationsFormateur/${user_id}`)
      .then(response => {
        setFormations(response.data);
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des formations:", error);
      })
      .finally(() => {
        setLoading(false); // Fin du chargement une fois les données récupérées
      });
  }, [setTitle]);

  const deleteFormation = (formation_id) => {
    axios.post(api + `/api/deleteFormation/${formation_id}`)
      .then(response => {
        setFormations(formations.filter(formation => formation.id !== formation_id));
        setIsOpen({...isOpen, success: true })
        setShowDeleteModal(false)
      })
      .catch(error => {
        setIsOpen({...isOpen, success: false })
      });
  }

  return (
    <>
      {/* <Header title="Formation" /> */}

      <div className='border-line mx-4 lg:mx-0 p-6 mt-6 min-h-[570px]'>
        <div className='flex w-full justify-between items-center mb-4'>
          <div className="flex flex-col text-start gap-3">
            <h1 className='text-xl font-semibold'>Liste des formations</h1>
            <p>Retrouvez la liste de vos séminaires et formations</p>
          </div>
          <button className='btn-black' onClick={() => navigate('/formateur/formation/new')}>Ajouter</button>
        </div>

        {loading ? (
          <div className="flex justify-center items-center h-[400px]">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-900"></div>
          </div>
        ) : formations.length === 0 ? (
          <NoResults showMessage={true} />
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
            {formations.map((formation, index) => (
              <div key={formation.id} className='border-line p-4'>
                <div className='flex justify-between w-full'>
                  <div className='flex items-center justify-start gap-4'>
                    <div className='w-[64px] h-[64px] border rounded-md bg-slate-100 flex items-center justify-center'>
                      <img 
                      src={api + '/storage/' + formation.photo || icon1} 
                      alt="..." 
                      className='object-cover w-full h-full'
                      onError={(e) => { e.target.onerror = null; e.target.src = formationIcon; }}/>
                    </div>
                    <div className='flex flex-col justify-between items-start'>
                      <h5 className="text-lg font-medium">{formation.titre}</h5>
                    </div>
                  </div>
                  <DropdownMenu key={index}
                    setShowDeleteModal={(state) => {
                      setDeleteFormationId(formation.id);
                      setShowDeleteModal(state);
                    }} 
                    idSelected={formation.id}
                  />
                </div>
                <p className='my-3 text-start'>{formation.description}</p>
                <hr className="my-6"/>
                <div className='flex justify-between mb-1'>
                  <p >Durée: <span className='font-semibold'>{formation.duree}</span></p>
                  <p>Créée le {new Date(formation.created_at).toLocaleDateString()}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {showDeleteModal && 
      <Modal width="800px" setShowModal={setShowDeleteModal}>
        <div className="relative w-full cursor-pointer pointer-events-none transition my-auto p-4">
          <div className="w-full py-2 bg-white cursor-default pointer-events-auto dark:bg-gray-800 relative rounded-xl mx-auto max-w-sm">
            <button tabIndex="-1" type="button" className="absolute top-2 right-2 rtl:right-auto rtl:left-2"
            onClick={() => setShowDeleteModal(false)}
            >
              <svg title="Close" tabIndex="-1" className="h-4 w-4 cursor-pointer text-gray-400"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"></path>
              </svg>
              <span className="sr-only">Close</span>
            </button>
            <div className="space-y-2 p-2">
              <div className="p-4 space-y-2 text-center dark:text-white">
                <h2 className="text-xl font-bold tracking-tight" id="page-action.heading">
                  Supprimer {formations.find(f => f.id === deleteFormationId)?.titre}
                </h2>
                <p className="text-gray-500">
                  Êtes-vous sûr de vouloir supprimer cette formation ?
                </p>
              </div>
            </div>
            <div className="space-y-2">
              <div aria-hidden="true" className="border-t dark:border-gray-700 px-2"></div>
              <div className="px-6 py-2">
                <div className="grid gap-2 grid-cols-[repeat(auto-fit,minmax(0,1fr))]">
                  <button 
                  type="button"
                  onClick={() => setShowDeleteModal(false)}
                    className="inline-flex items-center justify-center py-1 gap-1 font-medium rounded-lg border transition-colors outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset dark:focus:ring-offset-0 min-h-[2.25rem] px-4 text-sm text-gray-800 bg-white border-gray-300 hover:bg-gray-50 focus:ring-primary-600 focus:text-primary-600 focus:bg-primary-50 focus:border-primary-600 dark:bg-gray-800 dark:hover:bg-gray-700 dark:border-gray-600 dark:hover:border-gray-500 dark:text-gray-200 dark:focus:text-primary-400 dark:focus:border-primary-400 dark:focus:bg-gray-800">
                    <span className="flex items-center gap-1" >Annuler</span>
                  </button>
                  <button 
                    type="submit"
                    onClick={() => deleteFormation(deleteFormationId)}
                    className="inline-flex items-center justify-center py-1 gap-1 font-medium rounded-lg border transition-colors outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset dark:focus:ring-offset-0 min-h-[2.25rem] px-4 text-sm text-white shadow focus:ring-white border-transparent bg-red-600 hover:bg-red-500 focus:bg-red-700 focus:ring-offset-red-700">
                    <span className="flex items-center gap-1">Confirmer</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>}

      <Notification type="success" message="Suppression réussi." isOpen={isOpen.success} onClose={() => { setIsOpen({ ...isOpen, success: false }) }} />
      <Notification type="error" message="Une erreur s'est produite." isOpen={isOpen.fails} onClose={() => { setIsOpen({ ...isOpen, fails: false }) }} />
        
    </>
  );
}

export default Formation;
