import { useNavigate } from "react-router-dom";

// const navigate=useNavigate()

function logout() {
    localStorage.removeItem('naturagerme-id');
    localStorage.removeItem('naturagerme-type');
    localStorage.removeItem("naturagerme-photo")
    localStorage.removeItem("naturagerme-name")
    // navigate('/Login')
}

export {
    logout
}