import React from 'react'
import star from '../../assets/icons/trending-up-svgrepo-com.svg'

const ResumeCard = ({item}) => {
  const {title, number, statistic, image } = item

  return (
    <div className="w-full border-line bg-white">
      <div className="flex gap-4 p-4">
        <div className='w-[90px] h-[90px] bg-[#FEDF59]/20 rounded-lg flex items-center justify-center'>
        <img src={image} alt="..." className='w-10 h-10'/>
        </div>
        <div className="flex flex-col justify-between h-full gap-4">
          <div>{title}</div>
          <div className='text-xl'>{number}</div>
          {/* <div className='flex gap-1 text-[#FF8E29] items-center'><img src={star} alt="" className='w-5 h-5' />{statistic}%</div> */}
        </div>
      </div> 
    </div>
  )
}

export default ResumeCard