import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function ToLogin() {
    const navigate=useNavigate()
    return (
        <div className="flex flex-row justify-start items-center gap-x-4">
            <span>
                Vous avez déjà un compte ?
            </span>
            <button type='button' className="text-blue-400 " onClick={()=>{navigate('/Login')}}>
                Se connecter
            </button>
        </div>
    )
}
