import React, { useEffect, useState } from 'react';
import Input from '../../entrepreneur/components/form/Input';
import Select from '../../entrepreneur/components/form/select';
import { Button } from '@mui/material';
import { useNavigate, useOutletContext } from 'react-router-dom';
import axios from 'axios';
import { api } from '../../api';
import Load from '../../entrepreneur/components/load';
import Notification from '../../entrepreneur/components/feedback/Notification';
// import Button from '../../entrepreneur/components/btn/Button';
import ToLogin from '../../entrepreneur/components/toLogin';
export default function Personnel({ setPage }) {
    const navigate=useNavigate()
    const { data, setData } = useOutletContext()
    const [options, setOptions] = useState({
        loading: false,
        disabled: true
    })

    const [isOpen, setIsOpen] = useState({
        success: false,
        fails: false,
    })

    function save() {
        // setPage(prev => prev += 1)
        setOptions({ ...options, loading: true })
        try {
            const form = new FormData()
            form.append("nom", data.nom)
            form.append("prenom", data.prenom)
            form.append("nomJeuneFille", data.nomJeuneFille)
            form.append("adressePostale", data.adresse)
            form.append("tel", data.tel)
            form.append("pere", data.pere)
            form.append("mere", data.mere)
            form.append("sexe", data.sexe)
            form.append("dateNaissance", data.date_naissance)
            form.append("fax", data.fax)
            form.append("niveauFormation", data.niveau)
            form.append("fonctionEntreprise", data.fonction)
            form.append("pays", data.pays)
            form.append("user_id", localStorage.getItem("naturagerme-id"))
            axios.post(api + "/api/ajoutDonneePerso1", form)
                .then(res => {
                    console.log(res.data)
                    if(data.type=='Entrepreneur'){
                        setPage(prev => prev += 1)
                    } 
                    if(data.type=='Formateur'){
                        navigate('/Login')
                    }
                    // "Entrepreneur", "Formateur"
                    setOptions({ ...options, loading: false })

                })
                .catch(err => {
                    console.error(err)
                    setOptions({ ...options, loading: false })
                    setIsOpen({ ...isOpen, fails: true })

                })
        } catch (error) {
            console.error(error);
            setOptions({ ...options, loading: false })
            setIsOpen({ ...isOpen, fails: true })


        }
    }

    useEffect(() => {
        localStorage.getItem("naturagerme-id")
    }, [])

    useEffect(() => {
        if (
            // data.prenom != '' &&
            // data.nom != '' &&
            data.tel != ''
            // data.nomJeuneFille != '' &&
            // data.date_naissance != '' &&
            // data.sexe != '' &&
            // data.pere != '' &&
            // data.mere != '' &&
            // data.adresse != '' &&
            // data.fax != '' &&
            // data.niveau != '' &&
            // data.fonction != '' &&
            // data.langue_maternelle != '' &&
            // data.maitrise_langue_francaise != '' &&
            // data.ecrire_dans_cette_langue != '' &&
            // data.ecrire_en_francais != ''
        ) {
            setOptions({ ...options, disabled: false })
        } else {
            setOptions({ ...options, disabled: true })
        }
    }, [data])

    return (
        <form onSubmit={(e) => { e.preventDefault(); save() }} className="w-11/12 flex flex-col gap-y-2">
            <span className="font-black text-3xl">
                Données personnels
            </span>
           
            <div className="w-full flex flex-row justify-between items-center gap-x-3">

                <Input title={"Numero de téléphone"} value={data.tel} onChange={(e) => { setData({ ...data, tel: e.target.value }) }} />
            </div>
            <div className="w-full flex flex-row justify-between items-center gap-x-3">
                <Input title={"Pays"} value={data.pays} onChange={(e) => { setData({ ...data, pays: e.target.value }) }} />
                <Input title={"Nom de jeune fille"} value={data.nomJeuneFille} onChange={(e) => { setData({ ...data, nomJeuneFille: e.target.value }) }} />
            </div>
            <div className="w-full flex flex-row justify-between items-center gap-x-3">
                <Input title={"Date de naissance"} type="date" value={data.date_naissance} onChange={(e) => { setData({ ...data, date_naissance: e.target.value }) }} />
                <Select title={"Sexe"} values={["Masculin", "Feminin"]} value={data.sexe} onChange={(e) => { setData({ ...data, sexe: e.target.value }) }} />
            </div>
            <div className="w-full flex flex-row justify-between items-center gap-x-3">
                <Input title={"Père"} value={data.pere} onChange={(e) => { setData({ ...data, pere: e.target.value }) }} />
                <Input title={"Mère"} value={data.mere} onChange={(e) => { setData({ ...data, mere: e.target.value }) }} />
            </div>
            <div className="w-full flex flex-row justify-between items-center gap-x-3">
                <Input title={"Adresse postal"} value={data.adresse} onChange={(e) => { setData({ ...data, adresse: e.target.value }) }} />
                <Input title={"Fax"} value={data.fax} onChange={(e) => { setData({ ...data, fax: e.target.value }) }} />
            </div>
            <div className="w-full flex flex-row justify-between items-center gap-x-3">
                <Select title={"Quelle est votre niveau de formation ? "} values={["Sans formation", "Primaire", "Sécondaire", "BEPC", "Baccalauréat", "Supérieur"]} onChange={(e) => { setData({ ...data, niveau: e.target.value }) }} />
                <Select title={"Quelle est votre fonction dans l'entreprise ? "} values={["Propriétaire/Gérant", "Gérant", "Employé", "Gérant de la coperative", "Membre de la coopérative"]} onChange={(e) => { setData({ ...data, fonction: e.target.value }) }} />
            </div>
            <div className="w-full flex flex-row justify-between items-center gap-x-3">
                <Input title={"Quelle est votre langue maternelle ?"} value={data.langue_maternelle} onChange={(e) => { setData({ ...data, langue_maternelle: e.target.value }) }} />
                <Select title={"Comment maîtriser-vous la langue française ? "} values={["Très bien", "Bien", "Mal"]} onChange={(e) => { setData({ ...data, maitrise_langue_francaise: e.target.value }) }} />

            </div>
            <div className="w-full flex flex-row justify-between items-center gap-x-3">
                <Select title={"Pouvez-vous écrire dans cette langue ? "} values={["Oui", "Non"]} onChange={(e) => { setData({ ...data, ecrire_dans_cette_langue: e.target.value === "Oui" ? 1 : 0 }) }} />
                <Select title={"Pouvez-vous écrire en français ? "} values={["Oui", "Non"]} onChange={(e) => { setData({ ...data, ecrire_en_francais: e.target.value === 'Oui' ? 1 : 0 }) }} />
            </div>

            <div className="w-full flex max-lg:flex-col-reverse gap-y-2 lg:flex-row justify-between item-center">
                <ToLogin />
                {/* <Button variant='contained' onClick={() => { save() }}>Suivant</Button> */}
                <Button type='submit' disabled={options.disabled} sx={{
                    bgcolor: "#1e293b",
                    color: "white",
                    "&:hover": {
                        backgroundColor: "#1e293b", // Change the hover background color
                        color: "white", // Change the hover text color
                    },
                }} variant='contained' className="px-9 " >
                    <Load title={"Suivant"} loading={options.loading} next={true} />
                </Button>
            </div>
            <Notification type="error" message="Une erreur s'est produite." isOpen={isOpen.fails} onClose={() => { setIsOpen({ ...isOpen, fails: false }) }} />

        </form>
    )
}